.dashboard-client-category .css-1jllj6i-control{
    display: none;
}

.dashboard-client-category{
    -webkit-box-shadow: 0px 2px 14px -5px rgba(0,0,0,0.94); 
    box-shadow: 0px 2px 14px -5px rgba(0,0,0,0.94);
    /* border:1px solid rgb(144,197,68) !important; */


} 



.css-1r4vtzz{
    /* -webkit-box-shadow: 0px 2px 12px -3px rgba(0,0,0,0.57); 
    box-shadow: 0px 2px 12px -3px rgba(0,0,0,0.57) !important; */
    border:1px solid rgba(163, 147, 147,.1) !important;
   
}
.css-1r4vtzz:hover{
    /* -webkit-box-shadow: 0px 2px 12px -3px rgba(0,0,0,0.57); 
    box-shadow: 0px 2px 12px -3px rgba(0,0,0,0.57) !important; */
    border:1px solid rgba(163, 147, 147,.3) !important;
   
}