.datepicker-container
{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

}

.datepicker-container .month-box,
.datepicker-container .year-box
{
    flex: 1;
    flex-grow: 1;
    min-width: 0;
    margin: 0;
}