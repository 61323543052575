
input[type="file"].input-file {
    cursor: pointer !important;
    width: 100%;
    border: 1px solid #d6d6d6;
    border-radius: .2rem;
}

input[type="file"].input-file::-webkit-file-upload-button {
    background: rgba(0, 174, 243, 1);
    border: 0;
    padding: 0.5em 1.5em;
    cursor: pointer;
    color: #fff;
    border-radius: .2rem;
}

input[type="file"].input-file::-ms-browse.upload {
    background: rgba(0, 174, 243, 1);
    border: 0;
    padding: 0.5em 1.5em;
    cursor: pointer;
    color: #fff;
    border-radius: .2rem;
}