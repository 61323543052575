.bootstrap-demo.react-dropdown-tree-select .dropdown {
    width: 100% !important;
}

.bootstrap-demo .root {
    padding: 0px;
    margin: 0px;
}


.bootstrap-demo.react-dropdown-tree-select .dropdown-content {
    bottom: unset;
    left: unset;
    width: 100%;
    height: 30vh;
    overflow: auto;
    z-index: 10000;
}

.bootstrap-demo.react-dropdown-tree-select .dropdown .dropdown-trigger {
    max-width: 400px;
    border: 1px solid #d6d6d6;
    opacity: unset;
    width: 100%;
}

.bootstrap-demo.react-dropdown-tree-select .tag-item {
    min-width: 0%;
    display: inline-block;
    margin: 4px;
}

/* .bootstrap-demo.react-dropdown-tree-select input {
    width: 90%;
} */

.bootstrap-demo.react-dropdown-tree-select .tag-item:last-child {
    min-width: 92%;
    margin-right: 0.2rem;
}

.bootstrap-demo.react-dropdown-tree-select .node>label{
    display: flex;
    align-items: center;
    padding: 0.6 0.2;
}