.react-autosuggest__container {
  position: relative;
  width: 100%;
  color: black;
}

.react-autosuggest__input {
  width: 100%;
  color: #000000;
  background: #ffffff;
  border: 0;
  border-bottom: 1px solid #d6d6d6;
  padding: 10px;
  display: inline-block;
  vertical-align: middle;
  -webkit-transition: all 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition: all 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 35px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 13px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 8px 16px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}
