/* 20200403 build 1 - Africk */
/* #Tablet (Landscape) Design for a width of 1024px */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .insurance-dashboard-container{
        /* background-color: yellow; */
        width:84% !important;
        position: absolute;
        height: auto !important;
        position: relative;  /* 20200722 MABT-323 */
    }

}

/* #Tablet (Landscape) Design for a width of 1024px such as Ipad 9.7 inches */
@media only screen and (min-width: 1024px) {
    .insurance-dashboard-container{
        background-color: #ffffff;
        width: 320px !important;
        position: fixed;
        top: 57px;
        right: 100px !important;
        /* right: 100px; */
        height: 100% !important;
    }
}

/* #Mobile (Portrait) Design for a width of 320px */
@media only screen and (max-width: 767px) {
    .insurance-dashboard-container{
        /* background-color: yellow; */
        width:84% !important;
        position: relative;
        height: 100% !important;
    }
    
}

.insurance-dashboard-container{
    width: 300px;
    height: 93vh;
    /* float: right; */     /* 20200819 MABT-323 */
    border-left: 1px solid #eeeeee;
    padding: 15px 9px 15px 10px;
    overflow-y: auto;
}

.insurance-dashboard-container-shrinked{
    height: 93vh;
    float: right;
    border-left: 1px solid #eeeeee;
    padding: 15px 9px 15px 10px;
    overflow-y: auto;
    width: 60px !important;
}