@import url(/node_modules/rc-tooltip/assets/bootstrap_white.css);
.datepicker-container
{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

}

.datepicker-container .month-box,
.datepicker-container .year-box
{
    flex: 1 1;
    flex-grow: 1;
    min-width: 0;
    margin: 0;
}
.datepicker-container
{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

}

.datepicker-container .month-box,
.datepicker-container .year-box
{
    flex: 1 1;
    flex-grow: 1;
    min-width: 0;
    margin: 0;
}
.react-autosuggest__container {
  position: relative;
  width: 100%;
  color: black;
}

.react-autosuggest__input {
  width: 100%;
  color: #000000;
  background: #ffffff;
  border: 0;
  border-bottom: 1px solid #d6d6d6;
  padding: 10px;
  display: inline-block;
  vertical-align: middle;
  transition: all 300ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 35px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 13px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 8px 16px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

input[type="file"].upload {
    cursor: pointer !important;
    width: 100%;
    border-bottom: 1px solid #d6d6d6;
}

input[type="file"].upload::-webkit-file-upload-button {
    background: rgba(0, 174, 243, 1);
    border: 0;
    padding: 0.5em 1.5em;
    cursor: pointer;
    color: #fff;
    border-radius: .2em;
}

input[type="file"].upload::-ms-browse.upload {
    background: rgba(0, 174, 243, 1);
    border: 0;
    padding: 0.5em 1.5em;
    cursor: pointer;
    color: #fff;
    border-radius: .2em;
}
.textbox {
    border: 1px solid #d6d6d6;
    border-radius: 2px;
    width: 100%;
    height: 2rem;
}

input[type="file"].input-file {
    cursor: pointer !important;
    width: 100%;
    border: 1px solid #d6d6d6;
    border-radius: .2rem;
}

input[type="file"].input-file::-webkit-file-upload-button {
    background: rgba(0, 174, 243, 1);
    border: 0;
    padding: 0.5em 1.5em;
    cursor: pointer;
    color: #fff;
    border-radius: .2rem;
}

input[type="file"].input-file::-ms-browse.upload {
    background: rgba(0, 174, 243, 1);
    border: 0;
    padding: 0.5em 1.5em;
    cursor: pointer;
    color: #fff;
    border-radius: .2rem;
}
.bootstrap-demo.react-dropdown-tree-select .dropdown {
    width: 100% !important;
}

.bootstrap-demo .root {
    padding: 0px;
    margin: 0px;
}


.bootstrap-demo.react-dropdown-tree-select .dropdown-content {
    bottom: unset;
    left: unset;
    width: 100%;
    height: 30vh;
    overflow: auto;
    z-index: 10000;
}

.bootstrap-demo.react-dropdown-tree-select .dropdown .dropdown-trigger {
    max-width: 400px;
    border: 1px solid #d6d6d6;
    opacity: unset;
    width: 100%;
}

.bootstrap-demo.react-dropdown-tree-select .tag-item {
    min-width: 0%;
    display: inline-block;
    margin: 4px;
}

/* .bootstrap-demo.react-dropdown-tree-select input {
    width: 90%;
} */

.bootstrap-demo.react-dropdown-tree-select .tag-item:last-child {
    min-width: 92%;
    margin-right: 0.2rem;
}

.bootstrap-demo.react-dropdown-tree-select .node>label{
    display: flex;
    align-items: center;
    padding: 0.6 0.2;
}
.textarea {
    border: 1px solid #d6d6d6;
    border-radius: 2px;
    width: 100%;
}
/* SPECIFIC PAGE LAYOUT */
.login-page-wrapper {
  height: 100vh;
  width: 100%;
  background-image: url(https://koizai.com/app/static/media/login-bg.a54ceac7.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center; }

.login-page-wrapper .forgot-password {
  font-size: 0.8rem;
  width: 100%;
  display: block;
  margin-top: 3px;
  cursor: pointer; }

.login-page-wrapper .version {
  font-size: 0.8rem;
  width: 100%;
  display: block;
  margin-top: 3px; }

.login-page-wrapper .login-form-wrapper {
  width: 100%;
  height: auto;
  max-width: 900px;
  padding: 15px;
  display: flex; }

.login-page-wrapper .login-form-wrapper .login-image {
  height: auto;
  width: 520px;
  background-image: url(https://koizai.com/app/static/media/wallpaper.80767f1a.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  padding: 32px;
  display: block;
  display: -moz-flex;
  display: flex;
  -webkit-align-items: left;
  -webkit-moz-items: left;
  align-items: left;
  -moz-justify-content: left;
  justify-content: left;
  -moz-flex-direction: row;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row wrap;
  -moz-flex-direction: column;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 2px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px; }

.login-page-wrapper .login-form-wrapper .login-image .tagline h1,
.login-page-wrapper .login-form-wrapper .login-image .tagline h4 {
  color: #ffffff;
  font-size: 1.3rem;
  max-width: 355px; }

.login-page-wrapper .login-form-wrapper .login-image .tagline h4 {
  font-weight: 400; }

.login-page-wrapper .login-form-wrapper .login-form {
  display: inline-block;
  position: relative;
  width: 100%;
  padding: 32px;
  background-color: #ffffff;
  border-radius: 0 2px 2px 0;
  max-width: 350px; }

.login-page-wrapper .login-form-wrapper .login-form .logo {
  position: relative;
  width: 100%;
  max-width: 123px;
  height: auto; }

.login-page-wrapper .login-form-wrapper .login-form .message {
  display: none;
  color: #ffffff;
  font-weight: 600;
  font-size: 1rem;
  padding: 8px 16px;
  background-color: #7ed321;
  border-radius: 2px;
  margin-bottom: 24px; }

.login-page-wrapper .login-form-wrapper .login-form .message p {
  color: #ffffff;
  font-weight: 600;
  margin: 0;
  font-size: 1rem;
  position: relative;
  padding-left: 24px; }

.login-page-wrapper .login-form-wrapper .login-form .primary-btn {
  margin-top: 24px; }

.login-page-wrapper .login-form-wrapper .login-form.error .message {
  display: block;
  background-color: #ff5f5f; }

.login-page-wrapper .login-form-wrapper .login-form.error .message p::before {
  content: "";
  position: absolute;
  height: 14px;
  width: 14px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAkCAYAAAGIzpi9AAAAAXNSR0IArs4c6QAAAztJREFUWAnNWDtMFUEU5REViYkUFEqCBmNiLF5HSUmpsdXSlpaWRMSGltbWSkNlNKGkpJPCkKgQKhMx0UIjfuPnnHXu5O7M3N3Zz0uYZDJ3zj3nzGVmmV0YG6trf10T3rgE4TggQLJODNDGgc1pkDGwrx7DZM9PJABYsvLekkgteGcgWbExRxLRVkwCEyAskOVihn+SAiTeM8umyH5O7JQkOFqNCu5aMo/cbJgoXDVIBzcvuZRODpzTIkIcb79Kips/Acl5Rzg8FFBGYF4oWOGgE4xdmyIpdaZeLAG3JyKmMAi28h8Isa8aUetNKbiKl52D2aYYylgnLp1qSIbJJ2Dn0ddUbtXFM9inI4XXh1IVR83WOOIlnTNjEIsD0WJN1riLd3Q+ikEaJkRVlQr9V2RGANl1YbQdxVge0kMAVwQMR3lwiXPBMB/MJ7l/3wFOBInRT7HwLLp9V7QpAYbSbrTRRxq4rYgjx4jQBtCGLt5s4+M1MNlLmBKKLnQvQmD+7kN4Efl3jhz+7n/GY1bcsNqsNtYVarLCFzReG0O4pMSlw7HwHFOtNU1Bit5SSXMQd0qOmGhimMPcPJdCB8JUQlRnGl3U/oXrqvmgq8qML6CQoeb65w2J20j4uSaxej1PxC+BxdtAYce2XloMZltVhpqcw+N9ehaib1oYxg0u6UNwr9KU37eToVGH+Vzx5uxgkJL+SIGtMfzUz9WeP2ttNCohiptAP1BFSrjP3KjWbeSLQi6jH0tliZG5S41M+yajgMVEYRa02Pf6WX6oZtmqqAJfzjLvi4RCHoXFWN4hj1qL2xuORfg3/IvE4uY7IcV1HuFLrJ86YT6N/tFYuGmhtKHXdD/VORcYzqP/RjebtaAp+J+g57ylbYTD6G7NYkXaMs3Rcg1Ln4XDYCNzoTZHH1pvZBUVkuCyHTpVzUO9zKs0idy26MIx+jqD+BxIr9Cj/yuF4hHN38L3Oj6ZjrV/qVAUeQ1Jfl6e0aScWH+3aT53Tc8z45/gDeG5L3x/l8HvFsDX6I2LFLMeR9bwxtVU2BYf9QDuYbbWZSF43O+iN7RP4buKnX3Aj9zHIPEvkZPcnvDod9G/nOAqWdvuPxIN6BPENCA4AAAAAElFTkSuQmCC);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 2px;
  left: 0px; }

.login-page-wrapper .login-form-wrapper .login-form .login-greeting {
  margin: 0.7rem 0; }

.login-page-wrapper .login-form-wrapper .login-form .login-greeting:before {
  content: "";
  position: relative;
  width: 40px;
  height: 3px;
  display: block;
  margin-bottom: 4px; }

.login-page-wrapper .login-form-wrapper .login-form .login-greeting h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
  color: #34456c; }

.login-page-wrapper .login-form-wrapper .login-form .login-greeting p {
  font-size: 14px;
  margin: 0;
  color: #676d7a; }

@media only screen and (max-width: 900px) {
  .login-page-wrapper .login-form-wrapper .login-form {
    max-width: 330px; }
  .login-page-wrapper .login-form-wrapper .login-image {
    width: 320px; } }

.insurance .content-panel-title .title-holder {
  width: 100%;
  display: inline-block;
  padding: 0 15px 0 0; }

.insurance-box-holder {
  width: 100%;
  display: inline-block;
  padding: 0 15px 15px 0; }

.insurance-box-holder:nth-of-type(3n) {
  padding: 0 15px 0 0; }

/* CHART D3 */
#waveformchart svg {
  position: absolute;
  width: 100%;
  height: 100%; }

#waveformchart {
  width: 100%;
  height: 140px;
  background-color: #333;
  position: relative;
  margin-bottom: 20px; }

.axis {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges; }

.axis text {
  font: 1rem sans-serif;
  fill: #000;
  stroke: none;
  font-weight: bold; }

.axis line {
  stroke: #ccc; }

.axis path {
  stroke: #ccc; }

#waveformchart .bar {
  /* IE 9 */
  -webkit-transform: scale(1, 1);
  /* Safari */
  transform: scale(1, 1); }

#waveformchart .bar:hover {
  height: 100%;
  /* IE 9 */
  -webkit-transform: scale(2, 100);
  /* Safari */
  transform: scale(2, 100);
  /* IE 9 */
  -webkit-transform-origin: 50% 50%;
  /* Chrome, Safari, Opera */
  transform-origin: 50% 50%; }

.bar:hover {
  fill: #e55; }

.dot:hover {
  fill: #e55; }

.area:hover {
  fill: #e55; }

.line {
  padding: 10px; }

.line:hover {
  stroke: #e55; }

.arc path:hover {
  fill: #e55; }

.arc text {
  font: 1rem sans-serif;
  fill: #000;
  stroke: none;
  font-weight: bold; }

.tooltip {
  padding: 3px;
  border: 2px solid;
  border-radius: 4px;
  background-color: #eee;
  opacity: 0.6;
  justify-content: center;
  align-items: center; }

.brush .extent {
  stroke: #000;
  fill-opacity: 0.125;
  shape-rendering: crispEdges; }

.brush .background {
  fill: #ddd; }

.chart-holder .pie {
  text-align: center; }

.legend {
  list-style: none; }

.legend li {
  float: left;
  margin-right: 10px; }

.legend span {
  border: 1px solid #ccc;
  float: left;
  width: 16px;
  height: 16px;
  margin: 2px; }

.profile-pic {
  border-radius: 50%; }

#profpic {
  display: none; }

.profpic-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  color: #fff;
  background-color: #20a8d8;
  border-color: #20a8d8; }

.map-container-offering {
  height: 450px;
  width: 100%; }

.map-container-profile {
  height: 200px;
  width: 100%; }

.map-container-profile-public {
  height: 200px;
  width: 100%;
  margin-left: 35px;
  margin-right: 35px; }

.card-btn {
  float: right;
  margin-top: 25px; }

.card-title {
  font-size: 1.4em; }

.card-icon {
  padding-top: 5px; }

.offering-title {
  line-height: 1.5 !important; }

.applicant-calendar {
  height: 500px; }

.verified-text {
  color: #20a8d8;
  font-size: 1.4em;
  vertical-align: middle; }

.rating-color {
  color: #ffa500; }

.profile-subtitle {
  color: #a9a9a9;
  margin: 20px; }

.profile-data {
  margin: 10px;
  margin-left: 20px; }

.text-red {
  color: #dc3545;
  position: absolute;
  top: 47%;
  right: 35px;
  content: "\F12A";
  font-family: FontAwesome; }

.text-yellow {
  color: #dca735; }

main.malabar-main {
  height: 100vw; }

.tfa-switch {
  display: inline-block; }

/* Toggle */
.onoffswitch {
  position: relative;
  width: 90px;
  margin-left: 180px;
  margin-bottom: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; }

.onoffswitch-checkbox {
  display: none; }

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  height: 30px;
  padding: 0;
  border: 2px solid #999999;
  border-radius: 30px;
  background-color: #ada6a6;
  transition: background-color 0.3s ease-in; }

.onoffswitch-label:before {
  content: "";
  display: block;
  width: 30px;
  margin: 0px;
  background: #ffffff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 58px;
  border: 2px solid #999999;
  border-radius: 30px;
  transition: all 0.3s ease-in 0s; }

.onoffswitch-checkbox:checked + .onoffswitch-label {
  background-color: #4ac234; }

.onoffswitch-checkbox:checked + .onoffswitch-label,
.onoffswitch-checkbox:checked + .onoffswitch-label:before {
  border-color: #4ac234; }

.onoffswitch-checkbox:checked + .onoffswitch-label:before {
  right: 0px; }

/* 2fa box */
.tfa-box {
  margin: auto;
  width: 75%;
  text-align: center; }

.tfa-code-input {
  font-size: 1rem;
  width: 75%;
  height: 30px;
  margin-bottom: 10px; }

.color-red {
  color: red; }

.btn-green {
  background-color: #4ac234;
  width: 100px;
  font-size: 1rems;
  border-radius: 5px; }

.btn-yellow {
  background-color: #f0582a;
  width: 100px;
  font-size: 1rem;
  border-radius: 5px; }

.image-qrcode {
  margin-left: 90px; }

/* Activate */
.activate {
  font-size: 1.2rem;
  display: none;
  color: #4ac234; }

.form-box {
  margin-top: 70px; }

.form-top {
  overflow: hidden;
  padding: 0 25px 15px 25px;
  background: #0069d9;
  background: rgba(0, 0, 0, 0.35);
  border-radius: 4px 4px 0 0;
  text-align: left; }

.form-top-left {
  float: left;
  width: 75%;
  padding-top: 25px; }

.form-top-left h3 {
  margin-top: 0;
  color: #fff; }

.form-top-left p {
  opacity: 0.8;
  color: #fff; }

.form-top-right {
  float: left;
  width: 25%;
  padding-top: 5px;
  font-size: 1.5rem;
  color: #0069d9;
  text-align: right;
  opacity: 0.3; }

.form-bottom {
  background: #0069d9;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 0 0 4px 4px;
  text-align: left; }

.form-bottom form textarea {
  height: 100px; }

.form-bottom form button.btn {
  width: 100%; }

.form-bottom form a {
  width: 100%;
  color: white;
  background: #19b9e7; }

.form-bottom form p {
  text-align: center;
  color: white; }

.form-bottom form .input-error {
  border-color: #19b9e7; }

.calc-menu {
  float: left; }

.calc-scrollable {
  height: calc(100vh - 283px); }

.calc-scrollable-simple {
  height: calc(100vh - 215px); }

.calc-scrollable-with-menu {
  height: calc(100vh - 363px); }

.calc-scrollable-with-menu-submenu {
  height: calc(100vh - 380px); }

.full-width-root {
  width: 100%; }

.required-alert-text-red {
  top: 25%;
  font-family: "SourceSansProRegular"; }

.btn-remove {
  width: 26px;
  height: 26px; }

.identity-remove {
  position: relative !important;
  margin-left: 10px; }

.header-check-input-grid {
  position: unset;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.row-check-grid {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 13px; }

.table-blue th.dob {
  width: 240px; }

.white-button {
  color: #fff !important; }

.arrow {
  opacity: 0.3; }

.arrow:hover {
  opacity: 1; }

.portal-left {
  height: 100vh; }

.portal-maincontent .action-panel {
  height: calc(100vh - 40px);
  overflow-y: hidden;
  z-index: 99; }

.actions-panel {
  justify-content: flex-start; }

.page-arrow-holder {
  padding: 0 35px 0 15px; }

.portal-grid {
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 215px 260px 260px;
  align-items: baseline; }

.where-grid {
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: 50px 170px 30px;
  align-items: center; }

.currency-input {
  text-align: right; }

.acc-arrow-down {
  width: 30px;
  position: relative;
  -webkit-transform: translate(0px, 0);
          transform: translate(0px, 0);
  vertical-align: top;
  display: inline-block;
  padding: 5px 0 0 15px; }

.acc-arrow-down:before,
.acc-arrow-down:after {
  content: "";
  position: absolute;
  background-color: #7ed321;
  width: 3px;
  height: 9px; }

.map-legend {
  width: 45%; }

.disabled-link {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
  text-decoration: none;
  display: inline-block; }

.action-button-disabled,
.action-button-disabled:hover {
  cursor: not-allowed;
  background: #b3b1b2; }

.no-access-text-modal {
  padding: 20px;
  text-align: center; }

.hand {
  cursor: pointer; }

.alphabet-L a,
.alphabet-L span {
  cursor: pointer; }

.header-bar .title {
  display: inline-block;
  font: normal 1.2rem "SourceSansProRegular", Arial, Helvetica, sans-serif; }

.header-bar .user-control {
  width: 30%;
  display: inline-block;
  text-align: right; }

.year-age-input {
  width: 85%;
  display: inline-block; }

.goals .goalsbutton-panel {
  height: 95vh; }

.goals .copy-row {
  float: right; }

.goals-table td .goals-table-label {
  position: relative;
  left: 24px;
  color: white; }
  .goals-table td .goals-table-label.disabled {
    color: #606060 !important; }

.goals-table td.disabled .goals-table-label {
  color: white;
  color: #606060 !important; }

.goals-table td select {
  font-weight: normal;
  color: black; }

.goals-header-check {
  top: -16px; }

.goals-header-child-name {
  width: 16% !important; }

.goals-header-high-school {
  width: 230px !important; }

.goals-table {
  width: 100%;
  width: 1000px; }
  .goals-table input {
    font-weight: normal; }

.goals-header-height {
  line-height: 12px; }

.header-button-accordion {
  position: relative;
  top: -60px;
  left: 15px; }

.portal-form-row2 .row-holder2 .text-input {
  padding-left: 5px; }

.accesslevel-height-page {
  height: -webkit-calc(100vh - 100px);
  overflow-y: auto !important; }

.chart-slider {
  margin-left: 85px;
  margin-right: 10px; }
  .chart-slider .content {
    display: block;
    margin-left: auto;
    margin-right: auto; }

.tooltip-chart .default {
  margin: 0 8px; }

.tooltip-chart .bolded {
  font-weight: bold; }

.tooltip-chart .red {
  color: #ff2121; }

.tooltip-chart .currency {
  text-align: right; }

.percent-text {
  display: none; }

.inp-group {
  display: flex;
  align-items: center; }
  .inp-group div {
    padding: 10px;
    border-radius: 4px 0px 0px 4px;
    background: #d8d8d8;
    display: none; }
    .inp-group div select {
      background: transparent;
      font-weight: 600;
      font-size: 12px; }
      .inp-group div select option {
        color: #505050;
        font-style: normal;
        font-weight: 600;
        line-height: normal; }
  .inp-group input {
    color: #666666;
    border: 1px solid #d6d6d6;
    text-align: right;
    padding: 7px 10px;
    border-radius: 8px;
    transition: all 0.3s linear; }
    .inp-group input:not(:disabled) {
      border-radius: 0;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: 1px solid #d6d6d6; }
      .inp-group input:not(:disabled):focus {
        border-color: #000000; }

.cashflow-inp-total input {
  border-top: 1px double #000000;
  border-bottom: 1px double #000000;
  border-left: 0;
  border-right: 0;
  color: #000000;
  border-radius: 0; }

.disabled-cashflow option {
  background-color: #ebebe4; }

.inp-group-field {
  flex: 1 1;
  margin-left: 5px;
  width: 100%; }

.plain-input {
  border: none; }

.cashflow-inp:first-of-type {
  width: 50%;
  float: left; }

.cashflow-inp:last-of-type {
  width: 50%;
  margin-left: 5px;
  float: left; }

.cashflow-select {
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none; }

/* For IE10 */
.cashflow-select::-ms-expand {
  display: none; }

.float-left {
  float: left; }

.float-right {
  float: right; }

.cashflow-remove {
  margin-top: -25px; }

.current_standing .tabs {
  margin-top: 32px; }

.table-propertyplanning tr th:first-of-type {
  text-align: left;
  width: 15%; }

.table-propertyplanning tr th,
.table-propertyplanning tr th:last-of-type {
  width: calc(100% / 6);
  width: -moz-calc(1100%/6);
  text-align: right;
  padding: 5px 10px; }

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .current_standing .full-width {
    width: 97%; }
  .ratio-box .ratio-graph .graph-image-gauge {
    width: 100%;
    height: 100%;
    display: block; }
  .financialRatios .ratio-box .ratio-graph .graph-image-gauge {
    width: 100%;
    display: inline-block; } }

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .current_standing .full-width {
    width: 97%; }
  .ratio-box .ratio-graph .graph-image-gauge {
    width: 100%;
    height: 100%;
    display: block; } }

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1400px) {
  .current_standing .full-width {
    width: 100%; }
  .ratio-box .ratio-graph .graph-image-gauge {
    width: 100%;
    height: 100%;
    display: block; }
  .financialRatios .ratio-box .ratio-graph .graph-image-gauge {
    width: 100%;
    display: inline-block; } }

a {
  cursor: pointer; }

.btn-chevron-down {
  margin-left: 10px;
  cursor: pointer; }

.inv-alloc-menuitem {
  width: 70px; }

.error-status {
  color: #ff2121;
  font-weight: bold; }

.row-holder-phone {
  width: 535px !important; }

.portal-form-onethird .content-panel-header {
  padding: 0 0 3em 0;
  text-align: right; }

.portal-form-row2 .row-holder2 .dateformat .text-input {
  width: 25%; }

.anti-moneylaundering .row-holder .input-check {
  padding-top: 18px;
  float: left;
  display: block;
  width: 100%; }

.additional-information .portal-form-row {
  height: 20px;
  display: table; }

/* 20200929 MABT-413 */
/* 20200930 MABT-413 */
.additional-information .portal-form-row.weightInKg input.text-input {
  padding: 0 !important; }

/* 20200930 MABT-413 */
.additional-information .portal-form-row.heightInCm input.text-input {
  padding: 0 !important; }

/* 20200930 MABT-413 */
.additional-information .portal-form-row.dailySmokeQuantity input.text-input {
  padding: 0 !important; }

/* 20200930 MABT-413 */
.additional-information .portal-form-row.weeklyDrinkQuantity input.text-input {
  padding: 0 !important; }

.additional-information .portal-form-row .row-holder {
  padding: 0 10px; }

.additional-information .portal-form-row label,
.additional-information .portal-form-row .row-holder {
  vertical-align: middle;
  display: table-cell !important; }

.additional-information .portal-form-row label,
.additional-information .portal-form-row .row-holder .input-check {
  padding: 0; }

.group-label {
  font-weight: bold; }

.additional-information .portal-form-row .on-group {
  padding-left: 3em; }

.half-container {
  display: inline-block;
  width: 50%;
  padding: 0 5px; }

.text-center {
  text-align: center; }

._90p-width {
  width: 90%;
  display: inline-block; }

._10p-width {
  width: 10%;
  display: inline-block; }

input.text-input.fee-percentage {
  width: 50px;
  margin-right: 20px;
  margin-left: 0; }

.select-box.money-fee {
  width: 155px; }

.select-box.allocation-type-selector {
  width: 90px; }

.allocation-field span.text-input.percentage {
  border: none; }

.allocation-field span.text-input.percentage,
.allocation-field input.text-input.percentage {
  width: 25%; }

.error .allocation-field span.text-input.currency {
  color: white; }

.allocation-field span.text-input.currency {
  width: 20%;
  display: inline-block;
  text-align: right;
  border: none;
  background: none; }

.allocation-field span.text-input.money-value {
  border: none; }

.allocation-field span.text-input.money-value,
.allocation-field input.text-input.money-value {
  width: 55%; }

span.allocation-error {
  display: block;
  padding: 10px;
  border-radius: 10px;
  background-color: #ff6384;
  margin: 10px 0;
  color: white; }

td.allocation-cell.error {
  background-color: #ff6384; }

.mcwc-popover {
  z-index: 10 !important; }

h3 {
  font-size: 0.8rem; }

label {
  font-size: 0.8rem; }

.u-position-absolute {
  position: absolute; }

.u-position-relative {
  position: relative; }

.accordion__body {
  display: block; }

.accordion__body--hidden {
  display: none; }

.accordion__item + .accordion__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

.accordion__item--has-icon {
  position: relative; }

.accordion__title {
  cursor: pointer;
  width: 100%;
  text-align: left;
  border: none; }

.accordion__body {
  display: block;
  -webkit-animation: fadein 0.35s ease-in;
          animation: fadein 0.35s ease-in; }

.accordion__body--hidden {
  display: none;
  opacity: 0;
  -webkit-animation: fadein 0.35s ease-in;
          animation: fadein 0.35s ease-in; }

.accordion__title > *:last-child,
.accordion__body > *:last-child {
  margin-bottom: 0; }

.accordion__arrow {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 12px;
  top: 0;
  left: 10px;
  bottom: 6px;
  margin-top: -6px; }

.accordion__arrow::after,
.accordion__arrow::before {
  display: block;
  position: absolute;
  top: 50%;
  width: 10px;
  height: 2px;
  background-color: #7ed321;
  content: ""; }

.accordion__arrow::before {
  left: 4px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.accordion__arrow::before,
.accordion__arrow::after {
  transition: transform 0.25s ease, -webkit-transform 0.25s ease; }

.accordion__arrow::after {
  right: 4px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg); }

[aria-expanded="true"] .accordion__arrow::before,
[aria-selected="true"] .accordion__arrow::before {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg); }

[aria-expanded="true"] .accordion__arrow::after,
[aria-selected="true"] .accordion__arrow::after {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.right-arrow.accordion__arrow::before,
.right-arrow.accordion__arrow::before {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg); }

.right-arrow.accordion__arrow::after,
.right-arrow.accordion__arrow::after {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */
@-webkit-keyframes fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
@keyframes fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes move-down {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  10% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  20% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px); }
  30% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes move-down {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  10% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  20% {
    -webkit-transform: translateY(5px);
            transform: translateY(5px); }
  30% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes move-up {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  10% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  20% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px); }
  30% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes move-up {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  10% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  20% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px); }
  30% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

.accordion__title--animated:hover .accordion__arrow {
  -webkit-animation-name: move-down;
          animation-name: move-down;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s; }

.accordion__title--animated[aria-expanded="true"]:hover .accordion__arrow {
  -webkit-animation-name: move-up;
          animation-name: move-up;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s; }

.accordion__title .subtitle2 {
  width: 100%;
  display: block;
  font: normal 18px "SourceSansProBold", Arial, Helvetica, sans-serif; }

.accordion__title .subheader-title {
  width: 100%;
  display: block;
  font: normal 14px "SourceSansProBold", Arial, Helvetica, sans-serif; }

.accordion__arrow_cashflow_header {
  margin-left: 45px; }

.feedback-download {
  padding-top: 10px;
  padding-bottom: 10px;
  border-left: 1px solid #dfe4ec;
  border-right: 1px solid #dfe4ec;
  border-bottom: 1px solid #dfe4ec;
  padding-left: 10px; }
  .feedback-download a {
    color: #00aef3;
    cursor: pointer; }
  .feedback-download a:hover {
    opacity: 0.7; }
  .feedback-download i.fa {
    padding-right: 10px; }

.portal-form-row.feedback-form-row .portal-form-row {
  width: 85%; }

.portal-form-row.feedback-form-row:last-child .portal-form-row {
  display: inline-block; }

.feedback-form-row .itemvalidate.bad .alert-textarea {
  margin-top: 170px; }

.portal-form-row.feedback-form-row .text-area {
  width: 100%;
  margin-top: 9px;
  vertical-align: top;
  border: 1px solid #d6d6d6;
  outline: none;
  padding: 0; }

.portal-form-row.feedback-form-row .row-holder.text-area {
  width: 74%;
  vertical-align: top; }

.portal-form-feedback .feedback-selection.itemvalidate.bad {
  border: 1px solid #fb005f;
  margin-bottom: 5px; }
  .portal-form-feedback .feedback-selection.itemvalidate.bad .feedback-icon-holder {
    padding-left: 5px;
    padding-right: 5px; }

.malabar-modal-large.feedback-modal {
  position: absolute;
  margin-bottom: 20px; }

.malabar-modal-large.car-feedback-modal {
  margin-top: 10px;
  margin-bottom: 10px; }

.table-feedbacklog .rt-thead .rt-tr .rt-th,
.table-feedbacklog .rt-thead.-filters .rt-tr .rt-th {
  background: #ff9c00;
  border-right: 1px solid #ffffff; }

.table-clientlist .rt-thead .rt-tr .rt-th,
.table-clientlist .rt-thead.-filters .rt-tr .rt-th {
  background: #5597d9;
  border-right: 1px solid #ffffff; }

.table-feedbacklog .rt-thead.-filters .rt-tr .rt-th:last-of-type {
  border-right: none; }

.ReactTable .rt-th select,
.ReactTable .rt-th input {
  color: black; }

div.table-feedbacklog {
  border: none; }

.table-feedbacklog .rt-tbody .rt-td {
  padding: 5px 10px;
  text-align: center; }

.table-feedbacklog .rt-tbody .rt-td span {
  font-size: 20px; }

.table-feedbacklog .pagination-bottom {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #ff9c00;
  color: white; }

.table-feedbacklog .pagination-bottom .-pagination {
  border-top: 2px solid white; }

.table-feedbacklog .-pagination .-previous {
  border-bottom-left-radius: 8px;
  border-right: 1px solid white; }

.table-feedbacklog .-pagination .-next {
  border-bottom-right-radius: 8px;
  border-left: 1px solid white; }

.table-feedbacklog .-pagination button.-btn {
  color: white;
  background: transparent;
  font-weight: bold; }

.table-feedbacklog .-pagination .-pageJump,
.ReactTable .-pagination .-pageSizeOptions {
  color: black; }

.table-feedbacklog .pagination-bottom .-pagination .-btn:not([disabled]):hover {
  opacity: 0.85;
  background: transparent; }

.login-button {
  width: 280px;
  height: 40px;
  padding: 0; }

.login-input {
  margin-top: 15px; }

.verification-code-input {
  margin: 10px 0 15px 0; }

.itemvalidate .phone-alert {
  width: calc(99%); }

.itemvalidate .date-alert {
  width: calc(100% - 6px); }

.itemvalidate .email-alert {
  width: calc(100%); }

.itemvalidate .currency-alert {
  width: calc(100% - 3px); }

.itemvalidate .full-width {
  width: 100%; }

.itemvalidate .otheraddress-alert {
  width: calc(100% - 327px); }

.required-asterix {
  color: #fc534c;
  font: normal 1rem "SourceSansProRegular", Arial, Helvetica, sans-serif; }

.portal-form-row2 .row-holder2 .text-input {
  width: 100%;
  text-align: left;
  padding-left: 5px;
  margin-left: 0;
  float: none !important; }

.dateformat .row-holder2 .text-input {
  width: 29%;
  padding-left: 5px; }

.dateformat .row-holder2 .select-box {
  width: calc(50% - 50px);
  width: -moz-calc(50% - 44px);
  margin: 0 10px;
  height: 40px;
  float: none; }

.networth-other-input {
  width: 38% !important; }

.networth-other-col {
  width: 25% !important; }

.networth-other-remove {
  margin-left: 5px; }

.deletable-input {
  width: 55% !important; }

/* Dropdown Button */
.dropbtn {
  background-color: #3498DB;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer; }

/* Dropdown button on hover & focus */
.dropbtn:hover, .dropbtn:focus {
  background-color: #2980B9; }

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block; }

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1; }

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block; }

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd; }

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
  display: block; }

/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Library : _modal.scss
    Description : CSS for modal window
*/
.malabar-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.malabar-modal-bsownership {
  width: 90%; }

.malabar-modal-collectible {
  top: 560px;
  width: 80%; }

.malabar-modal-large.two-panes {
  top: auto;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  width: 80%; }

.malabar-modal-large.two-panes {
  top: auto;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  width: 80%; }

.many-tabs-side-pane .react-tabs__tab {
  max-width: 120px; }

.PrimaryResidenceProperty ul {
  width: 100%; }

/* 20200422 Car & Trasport */
.carTransportExpenses ul {
  display: inline-flex; }

/* 20200422 home Expenses */
.homeExpenses ul {
  display: inline-flex; }

.popup-networth .react-tabs__tab {
  width: auto; }

.malabar-modal-large.personal-contents {
  top: auto;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  width: 80%; }

.malabar-modal-large.motor-vehicle {
  top: auto;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  width: 90%; }

.malabar-modal-large.user-modal {
  width: 30%;
  margin-left: auto;
  margin-right: auto; }

.malabar-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(84, 84, 84, 0.75);
  z-index: 1000;
  overflow-y: auto; }

.malabar-modal-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  height: 100%; }

.row-holder.residence-insured-checkbox .row-holder {
  width: auto !important;
  margin-right: 0px; }

.close-button:hover {
  cursor: pointer; }

.ReactModalPortal .popup {
  min-width: 500px;
  overflow: auto; }

.popup {
  max-height: 650px; }

.popup-networth {
  background-color: white; }

.select-box {
  height: 36px; }

.CPFInvestmentLink {
  width: 90vw !important; }
  .CPFInvestmentLink .portal-form-popup {
    overflow: auto; }
  .CPFInvestmentLink .additional {
    min-width: 1000px;
    overflow: auto; }

.CPFModal {
  width: 70vw !important; }
  .CPFModal .delete-area {
    width: 90px; }
  .CPFModal .textfield-area {
    min-width: 95%; }
  .CPFModal .portal-form-popup {
    overflow: auto; }

.popup-networth.CentralProvidenceFundInformation {
  width: 90vw !important; }
  .popup-networth.CentralProvidenceFundInformation .w-30 {
    width: 33%; }
  .popup-networth.CentralProvidenceFundInformation .portal-form-popup {
    overflow: auto; }
    .popup-networth.CentralProvidenceFundInformation .portal-form-popup .section {
      min-width: 600px;
      margin-right: 1rem; }
      .popup-networth.CentralProvidenceFundInformation .portal-form-popup .section .portal-form-row label {
        width: 40%; }
      .popup-networth.CentralProvidenceFundInformation .portal-form-popup .section .portal-form-row .row-holder {
        width: 60%; }
    .popup-networth.CentralProvidenceFundInformation .portal-form-popup .dropdown {
      position: relative; }
      .popup-networth.CentralProvidenceFundInformation .portal-form-popup .dropdown .dropdown-content {
        display: none;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        bottom: 15px;
        left: 10px; }
        .popup-networth.CentralProvidenceFundInformation .portal-form-popup .dropdown .dropdown-content.show {
          display: block !important; }
        .popup-networth.CentralProvidenceFundInformation .portal-form-popup .dropdown .dropdown-content div {
          cursor: pointer;
          color: black;
          padding: 12px 16px;
          text-decoration: none;
          display: block; }
          .popup-networth.CentralProvidenceFundInformation .portal-form-popup .dropdown .dropdown-content div:hover {
            background-color: #f1f1f1; }
    .popup-networth.CentralProvidenceFundInformation .portal-form-popup .portal-form-table {
      min-width: 1200px; }

/* #Tablet (Landscape) Design for a width of 1024px */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .malabar-modal-large {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .healthAndMedicalInsurances {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .popup-networth {
    width: 1485px !important;
    overflow: auto; }
  .EmployeeProvidenceFund {
    width: 1300px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .PensionInformation {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .SuplementaryRetirementSchemeInformation {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .risk-profile {
    width: 1000px; }
  .totalDisabilityInsurances {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .employmentIncome {
    width: 800px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .InvestmentPropertyRentalIncome {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .termDeposit {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .TermDeposit {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .Share {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .UnitTrust {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .InvestmentPlatformForm-UnitTrust {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .unitTrusts {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .Bond {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .bonds {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .collectibles {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .InvestmentPlatform,
  .InvestmentFund {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .BusinessOwnership {
    width: 1000px !important;
    margin: auto; }
  .CollectibleAntiqueArts {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .ILASInvestmentModal {
    width: 1200px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .GEALAustralia {
    width: 1000px !important;
    margin: auto; }
  .GEALSingapore {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .GEALHongkong {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .GEALNewZealand {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .GEALMalaysia {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .PersonalTax {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .EmploymentTerminationPaymentsForm {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .InvestmentPlatformDetailsForm #unitTrustForm {
    width: 100% !important; }
  .unit_trusts {
    width: 1000px; }
  .InvestmentPlatform,
  .InvestmentFund {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .InvestmentPlatformForm {
    width: 1000px !important;
    margin: auto; }
  .BusinessOwnership {
    width: 1000px !important;
    margin: auto; }
  .CollectibleAntiqueArts {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .ILASInvestmentModal {
    width: 1200px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .GEALAustralia {
    width: 1000px !important;
    margin: auto; }
  .GEALSingapore {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .GEALHongkong {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .GEALNewZealand {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .GEALMalaysia {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .PersonalTax {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .EmploymentTerminationPaymentsForm {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .carTransportExpenses {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .homeExpenses {
    width: 1200px; }
  .healthInsurance {
    width: 1000px; }
  .otherInsurancePremium {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .creditCard {
    width: 1000px; }
  .PersonalLoan {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .educationLoan {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .collectibles {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .personalExpenses-shares {
    width: 1000px; }
  .personal_contents {
    width: 1000px; }
  .Share {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .UnitTrust {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .InvestmentPlatformForm-UnitTrust {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .Bond {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .bonds {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .collectibles {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .loanInsurances {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .otherGeneralInsurances {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .EducationLoan {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .CreditCard {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .PersonalLoan {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .cashAccount {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .CashAccount {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .InvestmentProperty {
    width: 1350px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .investmentAllocation {
    width: 800px !important;
    margin: auto; }
  .investmentPlatform,
  .InvestmentFund {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .UserProfile {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .RetirementSchemeUnitTrust {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .MandatoryProvidentFund {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .OccupationalRetirementSchemeOrdinance {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .SuperannuationFund {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .investmentAllocationForm {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .BeneficiaryDetailsTable {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .SMSFunds {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .investmentPlatformForm {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .BusinessOwnership {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .cashAccounts {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .termDeposits {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .shares {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .PersonalContents {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .MotorVehicle {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .motorVehicleInsurances {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .personalContentsInsurances {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .contentsInsurances {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .loanInsurances {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .OtherResidenceProperties {
    width: 1350px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .InvestmentPlatformForm {
    width: 1000px; }
  .InvestmentPlatformDetailsForm {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .EmployeeProvidenceFundDetailForm {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .InvestmentPlatformForm-EmployeeProvidenceFundDetailForm {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .PrimaryResidenceProperty {
    width: 1350px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); } }

/* #Tablet (Landscape) Design for a width of 1024px such as Ipad 9.7 inches */
@media only screen and (min-width: 1024px) {
  .malabar-modal-large {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .healthAndMedicalInsurances {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .popup-networth {
    width: inherit;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .popup-networth-epf {
    width: 1305px; }
  .PrimaryResidenceProperty {
    width: 1350px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .EmployeeProvidenceFund {
    width: 1300px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .PensionInformation {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .SuplementaryRetirementSchemeInformation {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .risk-profile {
    width: 1000px; }
  .totalDisabilityInsurances {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .employmentIncome {
    width: 800px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .InvestmentPropertyRentalIncome {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .unitTrusts {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .Bond {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .bonds {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .collectibles {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .InvestmentPlatformDetailsForm #unitTrustForm {
    width: 100% !important; }
  .unit_trusts {
    width: 1000px; }
  .InvestmentPlatform,
  .InvestmentFund {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .BusinessOwnership {
    width: 1000px !important;
    margin: auto; }
  .CollectibleAntiqueArts {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .ILASInvestmentModal {
    width: 1200px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .GEALAustralia {
    width: 1000px !important;
    margin: auto; }
  .GEALSingapore {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .GEALHongkong {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .GEALNewZealand {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .GEALMalaysia {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .PersonalTax {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .EmploymentTerminationPaymentsForm {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .carTransportExpenses {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .homeExpenses {
    width: 1200px; }
  .healthInsurance {
    width: 1000px; }
  .otherInsurancePremium {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .creditCard {
    width: 1000px; }
  .personalLoan {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .cashAccount {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .CashAccount {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .educationLoan {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .CreditCard {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .personalExpenses-shares {
    width: 1000px; }
  .personal_contents {
    width: 1000px; }
  .Share {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .otherGeneralInsurances {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .EducationLoan {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .CreditCard {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .PersonalLoan {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .cashAccount {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .CashAccount {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .termDeposit {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .TermDeposit {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .InvestmentProperty {
    width: 1350px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .investmentAllocation {
    width: 1000px !important;
    margin: auto; }
  .investmentPlatform,
  .InvestmentFund {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .UserProfile {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .RetirementSchemeUnitTrust {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .newListing {
    width: 1000px !important; }
  .MandatoryProvidentFund {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .OccupationalRetirementSchemeOrdinance {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .SuperannuationFund {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .investmentAllocationForm {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .BeneficiaryDetailsTable {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .SMSFunds {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .investmentPlatformForm {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .BusinessOwnership {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .cashAccounts {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .termDeposits {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .shares {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .PersonalContents {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .MotorVehicle {
    width: 1100px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .motorVehicleInsurances {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .personalContentsInsurances {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .collectibleInsurances {
    width: 1000px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .contentsInsurances {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .loanInsurances {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .OtherResidenceProperties {
    width: 1350px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .InvestmentPlatformForm {
    width: 1000px; }
  .InvestmentPlatformDetailsForm {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .EmployeeProvidenceFundDetailForm {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .InvestmentPlatformForm-EmployeeProvidenceFundDetailForm {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); } }

/* #Mobile (Portrait) Design for a width of 320px */
@media only screen and (max-width: 767px) {
  .popup-networth {
    width: 1250px !important;
    overflow: auto; }
  .malabar-modal-large {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .EmployeeProvidenceFund {
    width: 1300px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .PensionInformation {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .SuplementaryRetirementSchemeInformation {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .risk-profile {
    width: 1000px; }
  .totalDisabilityInsurances {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .employmentIncome {
    width: 800px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .InvestmentPropertyRentalIncome {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .termDeposit {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .TermDeposit {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .UnitTrust {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .InvestmentPlatformDetailsForm #unitTrustForm {
    width: 100% !important; }
  .unit_trusts {
    width: 1000px; }
  .carTransportExpenses {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .homeExpenses {
    width: 1200px; }
  .healthInsurance {
    width: 1000px; }
  .unitTrusts {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .otherInsurancePremium {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .creditCard {
    width: 1000px; }
  .CreditCard {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .PersonalLoan {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .educationLoan {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .personalExpenses-shares {
    width: 1000px; }
  .personal_contents {
    width: 1000px; }
  .loanInsurances {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .otherGeneralInsurances {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .EducationLoan {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .collectibles {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .InvestmentProperty {
    width: 1350px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .investmentAllocation {
    width: 1000px !important;
    margin: auto; }
  .investmentPlatform,
  .InvestmentFund {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .UserProfile {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .RetirementSchemeUnitTrust {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .MandatoryProvidentFund {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .OccupationalRetirementSchemeOrdinance {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .SuperannuationFund {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .investmentAllocationForm {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .BeneficiaryDetailsTable {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .SMSFunds {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .investmentPlatformForm {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .BusinessOwnership {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .cashAccounts {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .termDeposits {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .shares {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .PersonalContents {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .MotorVehicle {
    width: 1100px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .motorVehicleInsurances {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .personalContentsInsurances {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .contentsInsurances {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .loanInsurances {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .OtherResidenceProperties {
    width: 1350px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .InvestmentPlatformForm {
    width: 1000px; }
  .InvestmentPlatformDetailsForm {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .EmployeeProvidenceFundDetailForm {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .InvestmentPlatformForm-EmployeeProvidenceFundDetailForm {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .PrimaryResidenceProperty {
    width: 1350px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .Share {
    width: 1000px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .CashAccount {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .GEALAustralia {
    width: 1000px !important;
    margin: auto; }
  .GEALSingapore {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .GEALHongkong {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .GEALNewZealand {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .GEALMalaysia {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .PersonalTax {
    width: 600px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .EmploymentTerminationPaymentsForm {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); } }

.malabar-modal-no-margin {
  margin: 0; }

.malabar-modal.FinanceDetails .portal-form.portal-form-networthdialog {
  display: flex;
  justify-content: space-between;
  width: 100% !important;
  max-height: 480px;
  overflow: initial; }

.malabar-modal.FinanceDetails .popup.popup-networth {
  overflow: initial; }

.malabar-modal.FinanceDetails .loan-details-wrapper {
  flex-basis: 75%;
  overflow: auto;
  padding-right: 20px; }

.malabar-modal.FinanceDetails .loan-info-wrapper {
  width: initial;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .malabar-modal.FinanceDetails .loan-info-wrapper .portal-form-row {
    flex-basis: 45%; }

.malabar-modal.FinanceDetails .page-title {
  top: -1px; }

.malabar-modal.FinanceDetails .graph-panel-networthdialog {
  overflow: auto;
  position: static;
  padding: 20px;
  width: initial;
  flex-grow: 1; }

.malabar-modal.FinanceDetails .portal-form-popup-networthdialog {
  padding-bottom: 50px; }

/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Library : _tabs.scss
    Description : CSS for tabs
*/
.react-tabs {
  -webkit-tap-highlight-color: transparent; }
  .react-tabs__tab-list {
    border-bottom: 1px solid #d6d6d6;
    margin: 0 0 10px;
    padding: 0; }
  .react-tabs__tab {
    font: normal 1rem "SourceSansProRegular", Arial, Helvetica, sans-serif;
    color: #666666;
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    padding: 10px 25px 10px 25px;
    height: 40px; }
    .react-tabs__tab.tab-active {
      background: green;
      color: white;
      border-bottom: 0; }
    .react-tabs__tab:hover {
      border-bottom: 3px solid #7ed321; }
    .react-tabs__tab--selected {
      border-bottom: 3px solid #7ed321; }
    .react-tabs__tab--disabled {
      color: GrayText;
      cursor: default; }
    .react-tabs__tab:focus:after {
      content: "";
      position: absolute;
      height: 5px;
      left: -4px;
      right: -4px;
      bottom: -5px;
      background: #fff; }
  .react-tabs__tab-panel {
    display: none; }
    .react-tabs__tab-panel--selected {
      display: block; }

.new-tabList {
  display: flex;
  align-items: center;
  padding: 0;
  grid-gap: 4px;
  gap: 4px;
  flex-wrap: wrap; }

.custom_tabs {
  background-color: white;
  position: -webkit-sticky;
  position: sticky;
  top: -15px;
  z-index: 20;
  border-bottom: 1px solid rgba(128, 128, 128, 0.3);
  padding-bottom: 5px; }

.table-blue .rt-th {
  font-weight: bold;
  padding: 10px 5px;
  text-align: center;
  background: #00b0f0;
  color: #ffffff;
  border-right: 1px solid #91d6ef; }

.table-blue .rt-thead .rt-th.-cursor-pointer:first-of-type,
.table-blue .rt-thead .rt-th.rt-resizable-header:first-of-type {
  border-top-left-radius: 8px; }

.table-blue .rt-thead .rt-th.-cursor-pointer:last-of-type,
.table-blue .rt-thead .rt-th.rt-resizable-header:last-of-type {
  width: 34px;
  border-top-right-radius: 8px;
  border-right: 0; }

.table-blue .rt-tbody .rt-td {
  background: #f4f7fb;
  padding: 2px;
  border-right: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff; }

.table-blue .rt-tbody .rt-td > div {
  text-align: center; }

.back-to-top {
  width: 46px;
  height: 46px;
  position: fixed;
  bottom: 55px;
  right: calc(35px + 100px);
  right: calc(35px + var(--action-panel-width, 100px));
  z-index: 50; }

.dashboard-content-panel .back-to-top {
  position: fixed;
  bottom: 5px;
  margin-left: auto; }

.ReactTable .rt-table .rt-thead .rt-th {
  line-height: 30px; }

.ReactTable .rt-table .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-table .rt-thead .rt-th.-sort-desc {
  box-shadow: none; }

.ReactTable .rt-thead .rt-th.-sort-desc span.ico-arrowtop::before {
  content: "\E90A"; }

.ReactTable .rt-thead .rt-th.-sort-asc span.ico-arrowtop::before {
  content: "\E90B"; }

.ReactTable .rt-thead .rt-th.-sort-asc span.ico-arrowtop,
.ReactTable .rt-thead .rt-th.-sort-desc span.ico-arrowtop {
  display: inline; }

.ReactTable .rt-thead .rt-th span.ico-arrowtop {
  display: none;
  font-size: 1rem;
  padding-left: 10px; }

@media only screen and (max-width: 1025px) {
  .box-half {
    width: 100%; } }

.table-icon {
  cursor: pointer;
  font-size: 1rem;
  margin: 10px; }

table.cpf-summary-table {
  width: 100%; }
  table.cpf-summary-table th {
    padding: 0.3rem 0.5rem; }
    table.cpf-summary-table th.first-col {
      min-width: 300px;
      width: 300px; }
    table.cpf-summary-table th .select-box {
      height: 36px; }
      table.cpf-summary-table th .select-box select {
        height: 36px; }
    table.cpf-summary-table th .title-box {
      text-align: center;
      color: #666666;
      box-sizing: border-box;
      border-radius: 0.5rem;
      background: #d4f2fe;
      padding: 0.5rem 0.5rem; }
  table.cpf-summary-table td {
    padding: 0.3rem 0.5rem; }
    table.cpf-summary-table td.first-col {
      min-width: 300px;
      width: 300px; }

/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Library : _radial.scss
    Description : CSS for radial progress indicator (https://codepen.io/jo-asakura/pen/stFHi)
*/
@media only screen and (max-width: 767px) {
  .set-size-small {
    font-size: 30vw;
    /* 20201106 MABT-423 */
    background-color: gold; } }

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .set-size-small {
    font-size: 20vw;
    /* 20201106 MABT-423 */ } }

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .set-size-small {
    font-size: 18vw;
    /* 20201106 MABT-423 */ } }

@media only screen and (min-width: 1024px) {
  .set-size-small {
    font-size: calc(12vw - 50px);
    /* 20201106 MABT-423 */ } }

.set-size-large {
  font-size: 20em; }

.charts-container {
  display: flex;
  align-items: center;
  justify-content: center; }
  .charts-container:after {
    clear: both;
    content: '';
    display: table; }

.pie-wrapper {
  height: 1em;
  width: 1em;
  float: left;
  margin: 15px;
  position: relative; }
  .pie-wrapper:nth-child(3n + 1) {
    clear: both; }
  .pie-wrapper .pie {
    height: 100%;
    width: 100%;
    clip: rect(0, 1em, 1em, 0.5em);
    left: 0;
    position: absolute;
    top: 0; }
    .pie-wrapper .pie .half-circle {
      height: 100%;
      width: 100%;
      border: 0.1em solid #3498db;
      border-radius: 50%;
      clip: rect(0, 0.5em, 1em, 0);
      left: 0;
      position: absolute;
      top: 0; }
  .pie-wrapper .label {
    background: #34495e;
    border-radius: 50%;
    bottom: 0.4em;
    color: #ecf0f1;
    cursor: default;
    display: block;
    font-size: 0.25em;
    left: 0.4em;
    line-height: 2.8em;
    position: absolute;
    right: 0.4em;
    text-align: center;
    top: 0.57143em; }
    .pie-wrapper .label .smaller {
      color: #bdc3c7;
      font-size: .45em;
      padding-bottom: 20px;
      vertical-align: super; }
    .pie-wrapper .label .pie-subtitle {
      color: #bdc3c7;
      font-size: .3em;
      vertical-align: text-top; }
  .pie-wrapper .shadow {
    height: 100%;
    width: 100%;
    border: 0.1em solid #bdc3c7;
    border-radius: 50%; }
  .pie-wrapper.style-2 .label {
    background: none;
    color: #7f8c8d; }
    .pie-wrapper.style-2 .label .smaller {
      color: #bdc3c7; }
  .pie-wrapper.progress-5 .pie .half-circle {
    border-color: #1abc9c; }
  .pie-wrapper.progress-5 .pie .left-side {
    -webkit-transform: rotate(18deg);
            transform: rotate(18deg); }
  .pie-wrapper.progress-5 .pie .right-side {
    display: none; }
  .pie-wrapper.progress-10 .pie .half-circle {
    border-color: #1abc9c; }
  .pie-wrapper.progress-10 .pie .left-side {
    -webkit-transform: rotate(36deg);
            transform: rotate(36deg); }
  .pie-wrapper.progress-10 .pie .right-side {
    display: none; }
  .pie-wrapper.progress-15 .pie .half-circle {
    border-color: #1abc9c; }
  .pie-wrapper.progress-15 .pie .left-side {
    -webkit-transform: rotate(54deg);
            transform: rotate(54deg); }
  .pie-wrapper.progress-15 .pie .right-side {
    display: none; }
  .pie-wrapper.progress-20 .pie .half-circle {
    border-color: #1abc9c; }
  .pie-wrapper.progress-20 .pie .left-side {
    -webkit-transform: rotate(72deg);
            transform: rotate(72deg); }
  .pie-wrapper.progress-20 .pie .right-side {
    display: none; }
  .pie-wrapper.progress-25 .pie .half-circle {
    border-color: #1abc9c; }
  .pie-wrapper.progress-25 .pie .left-side {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  .pie-wrapper.progress-25 .pie .right-side {
    display: none; }
  .pie-wrapper.progress-30 .pie .half-circle {
    border-color: #1abc9c; }
  .pie-wrapper.progress-30 .pie .left-side {
    -webkit-transform: rotate(108deg);
            transform: rotate(108deg); }
  .pie-wrapper.progress-30 .pie .right-side {
    display: none; }
  .pie-wrapper.progress-35 .pie .half-circle {
    border-color: #1abc9c; }
  .pie-wrapper.progress-35 .pie .left-side {
    -webkit-transform: rotate(126deg);
            transform: rotate(126deg); }
  .pie-wrapper.progress-35 .pie .right-side {
    display: none; }
  .pie-wrapper.progress-40 .pie .half-circle {
    border-color: #1abc9c; }
  .pie-wrapper.progress-40 .pie .left-side {
    -webkit-transform: rotate(144deg);
            transform: rotate(144deg); }
  .pie-wrapper.progress-40 .pie .right-side {
    display: none; }
  .pie-wrapper.progress-45 .pie .half-circle {
    border-color: #1abc9c; }
  .pie-wrapper.progress-45 .pie .left-side {
    -webkit-transform: rotate(162deg);
            transform: rotate(162deg); }
  .pie-wrapper.progress-45 .pie .right-side {
    display: none; }
  .pie-wrapper.progress-50 .pie .half-circle {
    border-color: #1abc9c; }
  .pie-wrapper.progress-50 .pie .left-side {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .pie-wrapper.progress-50 .pie .right-side {
    display: none; }
  .pie-wrapper.progress-55 .pie {
    clip: rect(auto, auto, auto, auto); }
    .pie-wrapper.progress-55 .pie .half-circle {
      border-color: #1abc9c; }
    .pie-wrapper.progress-55 .pie .left-side {
      -webkit-transform: rotate(198deg);
              transform: rotate(198deg); }
    .pie-wrapper.progress-55 .pie .right-side {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
  .pie-wrapper.progress-60 .pie {
    clip: rect(auto, auto, auto, auto); }
    .pie-wrapper.progress-60 .pie .half-circle {
      border-color: #1abc9c; }
    .pie-wrapper.progress-60 .pie .left-side {
      -webkit-transform: rotate(216deg);
              transform: rotate(216deg); }
    .pie-wrapper.progress-60 .pie .right-side {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
  .pie-wrapper.progress-65 .pie {
    clip: rect(auto, auto, auto, auto); }
    .pie-wrapper.progress-65 .pie .half-circle {
      border-color: #1abc9c; }
    .pie-wrapper.progress-65 .pie .left-side {
      -webkit-transform: rotate(234deg);
              transform: rotate(234deg); }
    .pie-wrapper.progress-65 .pie .right-side {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
  .pie-wrapper.progress-70 .pie {
    clip: rect(auto, auto, auto, auto); }
    .pie-wrapper.progress-70 .pie .half-circle {
      border-color: #1abc9c; }
    .pie-wrapper.progress-70 .pie .left-side {
      -webkit-transform: rotate(252deg);
              transform: rotate(252deg); }
    .pie-wrapper.progress-70 .pie .right-side {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
  .pie-wrapper.progress-75 .pie {
    clip: rect(auto, auto, auto, auto); }
    .pie-wrapper.progress-75 .pie .half-circle {
      border-color: #1abc9c; }
    .pie-wrapper.progress-75 .pie .left-side {
      -webkit-transform: rotate(270deg);
              transform: rotate(270deg); }
    .pie-wrapper.progress-75 .pie .right-side {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
  .pie-wrapper.progress-80 .pie {
    clip: rect(auto, auto, auto, auto); }
    .pie-wrapper.progress-80 .pie .half-circle {
      border-color: #1abc9c; }
    .pie-wrapper.progress-80 .pie .left-side {
      -webkit-transform: rotate(288deg);
              transform: rotate(288deg); }
    .pie-wrapper.progress-80 .pie .right-side {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
  .pie-wrapper.progress-85 .pie {
    clip: rect(auto, auto, auto, auto); }
    .pie-wrapper.progress-85 .pie .half-circle {
      border-color: #1abc9c; }
    .pie-wrapper.progress-85 .pie .left-side {
      -webkit-transform: rotate(306deg);
              transform: rotate(306deg); }
    .pie-wrapper.progress-85 .pie .right-side {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
  .pie-wrapper.progress-90 .pie {
    clip: rect(auto, auto, auto, auto); }
    .pie-wrapper.progress-90 .pie .half-circle {
      border-color: #1abc9c; }
    .pie-wrapper.progress-90 .pie .left-side {
      -webkit-transform: rotate(324deg);
              transform: rotate(324deg); }
    .pie-wrapper.progress-90 .pie .right-side {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
  .pie-wrapper.progress-95 .pie {
    clip: rect(auto, auto, auto, auto); }
    .pie-wrapper.progress-95 .pie .half-circle {
      border-color: #1abc9c; }
    .pie-wrapper.progress-95 .pie .left-side {
      -webkit-transform: rotate(342deg);
              transform: rotate(342deg); }
    .pie-wrapper.progress-95 .pie .right-side {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
  .pie-wrapper.progress-100 .pie {
    clip: rect(auto, auto, auto, auto); }
    .pie-wrapper.progress-100 .pie .half-circle {
      border-color: #1abc9c; }
    .pie-wrapper.progress-100 .pie .left-side {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg); }
    .pie-wrapper.progress-100 .pie .right-side {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }

.pie-wrapper--solid {
  border-radius: 50%;
  overflow: hidden; }
  .pie-wrapper--solid:before {
    border-radius: 0 100% 100% 0 / 50%;
    content: '';
    display: block;
    height: 100%;
    margin-left: 50%;
    -webkit-transform-origin: left;
            transform-origin: left; }
  .pie-wrapper--solid .label {
    background: transparent; }
  .pie-wrapper--solid.progress-65 {
    background: linear-gradient(to right, #e67e22 50%, #34495e 50%); }
    .pie-wrapper--solid.progress-65:before {
      background: #e67e22;
      -webkit-transform: rotate(126deg);
              transform: rotate(126deg); }
  .pie-wrapper--solid.progress-25 {
    background: linear-gradient(to right, #9b59b6 50%, #34495e 50%); }
    .pie-wrapper--solid.progress-25:before {
      background: #34495e;
      -webkit-transform: rotate(-270deg);
              transform: rotate(-270deg); }
  .pie-wrapper--solid.progress-88 {
    background: linear-gradient(to right, #3498db 50%, #34495e 50%); }
    .pie-wrapper--solid.progress-88:before {
      background: #3498db;
      -webkit-transform: rotate(43.2deg);
              transform: rotate(43.2deg); }

.box-arrow {
  border: 0;
  text-align: center; }

.performance-arrow-up {
  color: #00AF50; }
  .performance-arrow-up .arrow-title {
    font-size: 4em;
    color: #717171;
    font-weight: bold; }
  .performance-arrow-up .arrow-icon {
    font-size: 5em; }
  .performance-arrow-up .arrow-text {
    font-weight: bold;
    font-size: 3em; }

.performance-arrow-down {
  color: #FE0000; }
  .performance-arrow-down .arrow-title {
    font-size: 4em;
    color: #717171;
    font-weight: bold; }
  .performance-arrow-down .arrow-icon {
    font-size: 5em; }
  .performance-arrow-down .arrow-text {
    font-weight: bold;
    font-size: 3em; }

.performance-arrow-constant {
  color: #ff9900; }
  .performance-arrow-constant .arrow-title {
    font-size: 4em;
    color: #717171;
    font-weight: bold; }
  .performance-arrow-constant .arrow-icon {
    font-size: 5em; }
  .performance-arrow-constant .arrow-text {
    font-weight: bold;
    font-size: 3em; }

/*
    Author : Anova Fawzi (anovafawzi@gmail.com)
    Description : menu related style
*/
.dropdown {
  position: absolute;
  display: inline-block; }

.dropdown-currenstanding-grid {
  position: relative !important;
  display: inline-flex !important; }

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  bottom: 15px;
  left: -160px; }

.dropdown-content-currentstanding-investment {
  display: none;
  position: fixed;
  background-color: #f9f9f9;
  min-width: 60px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 260px;
  right: 400px; }

.dropdown-content-currentstanding-insurance {
  display: none;
  position: fixed;
  background-color: #f9f9f9;
  min-width: 60px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 337px;
  right: 400px; }

.dropdown-content-currentstanding-factFind {
  display: none;
  position: fixed;
  background-color: #f9f9f9;
  min-width: 60px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 260px;
  right: 400px; }

.dropdown-content-currentstanding-fna {
  display: none;
  position: fixed;
  background-color: #f9f9f9;
  min-width: 60px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 260px;
  right: 260px; }

.dropdown-content-currentstanding-financialPlan {
  display: none;
  position: fixed;
  background-color: #f9f9f9;
  min-width: 60px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 260px;
  right: 400px; }

.dropdown-content a,
.dropdown-content span {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer; }

.dropdown-content-currentstanding-investment a,
.dropdown-content-currentstanding-investment span {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer; }

.dropdown-content-currentstanding-insurance a,
.dropdown-content-currentstanding-insurance span {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer; }

.dropdown-content-currentstanding-factFind a,
.dropdown-content-currentstanding-factFind span {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer; }

.dropdown-content-currentstanding-fna a,
.dropdown-content-currentstanding-fna span {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer; }

.dropdown-content-currentstanding-financialPlan a,
.dropdown-content-currentstanding-financialPlan span {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer; }

.dropdown-content a:hover,
.dropdown-content span:hover {
  background-color: #f1f1f1; }

.dropdown-content-currentstanding-investment a:hover,
.dropdown-content-currentstanding-investment span:hover {
  background-color: #f1f1f1; }

.dropdown-content-currentstanding-insurance a:hover,
.dropdown-content-currentstanding-insurance span:hover {
  background-color: #f1f1f1; }

.dropdown-content-currentstanding-factFind a:hover,
.dropdown-content-currentstanding-factFind span:hover {
  background-color: #f1f1f1; }

.dropdown-content-currentstanding-fna a:hover,
.dropdown-content-currentstanding-fna span:hover {
  background-color: #f1f1f1; }

.dropdown-content-currentstanding-financialPlan a:hover,
.dropdown-content-currentstanding-financialPlan span:hover {
  background-color: #f1f1f1; }

.dropdown:hover .dropdown-content {
  display: block; }

.dropdown:hover .dropdown-content-currentstanding-investment {
  display: block; }

.dropdown:hover .dropdown-content-currentstanding-insurance {
  display: block; }

.dropdown:hover .dropdown-content-currentstanding-factFind {
  display: block; }

.dropdown:hover .dropdown-content-currentstanding-fna {
  display: block; }

.dropdown:hover .dropdown-content-currentstanding-financialPlan {
  display: block; }

.portal-left nav.primary-menu ul li input[type="checkbox"] {
  width: 0; }

.pagination {
  display: flex;
  list-style-type: none; }

.pagination li a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none; }

.pagination li.active a {
  background-color: #00B0F0;
  color: white;
  border-radius: 5px; }

.pagination li a:hover:not(.active) {
  background-color: #ddd;
  border-radius: 5px; }

.pagination-center {
  display: flex;
  justify-content: center;
  bottom: -25px;
  width: 100%; }

.rc-slider {
  position: relative;
  height: 14px;
  padding: 5px 0;
  width: 100%;
  border-radius: 6px;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: #e9e9e9;
  height: 4px;
  border-radius: 6px; }

.rc-slider-track {
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 6px;
  background-color: #ec5151; }

.rc-slider-handle {
  position: absolute;
  margin-left: -7px;
  margin-top: -5px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  cursor: grab;
  border-radius: 50%;
  border: solid 2px #fd9292;
  background-color: #fff;
  touch-action: pan-x; }

.rc-slider-handle:focus {
  border-color: #ff2121;
  box-shadow: 0 0 0 5px #fd9292;
  outline: none; }

.rc-slider-handle-click-focused:focus {
  border-color: #fd9292;
  box-shadow: unset; }

.rc-slider-handle:hover {
  border-color: #ff2121; }

.rc-slider-handle:active {
  border-color: #ff2121;
  box-shadow: 0 0 5px #ff2121;
  cursor: grabbing; }

.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 1rem; }

.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999; }

.rc-slider-mark-text-active {
  color: #666; }

.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent; }

.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle; }

.rc-slider-dot-active {
  border-color: #fd9292; }

.rc-slider-disabled {
  background-color: #e9e9e9; }

.rc-slider-disabled .rc-slider-track {
  background-color: #ccc; }

.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
  border-color: #ccc;
  box-shadow: none;
  background-color: #fff;
  cursor: not-allowed; }

.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important; }

.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px; }

.rc-slider-vertical .rc-slider-rail {
  height: 100%;
  width: 4px; }

.rc-slider-vertical .rc-slider-track {
  left: 5px;
  bottom: 0;
  width: 4px; }

.rc-slider-vertical .rc-slider-handle {
  margin-left: -5px;
  margin-bottom: -7px;
  touch-action: pan-y; }

.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 18px;
  height: 100%; }

.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 4px; }

.rc-slider-vertical .rc-slider-dot {
  left: 2px;
  margin-bottom: -4px; }

.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -4px; }

.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px; }

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  -webkit-animation-duration: .3s;
          animation-duration: .3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  display: block !important;
  -webkit-animation-play-state: paused;
          animation-play-state: paused; }

.rc-slider-tooltip-zoom-down-leave {
  -webkit-animation-duration: .3s;
          animation-duration: .3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  display: block !important;
  -webkit-animation-play-state: paused;
          animation-play-state: paused; }

.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  -webkit-animation-name: rcSliderTooltipZoomDownIn;
          animation-name: rcSliderTooltipZoomDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running; }

.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  -webkit-animation-name: rcSliderTooltipZoomDownOut;
          animation-name: rcSliderTooltipZoomDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running; }

.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  -webkit-transform: scale(0, 0);
          transform: scale(0, 0);
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1); }

.rc-slider-tooltip-zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06); }

@-webkit-keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0); }
  100% {
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0); }
  100% {
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); } }

@-webkit-keyframes rcSliderTooltipZoomDownOut {
  0% {
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0); } }

@keyframes rcSliderTooltipZoomDownOut {
  0% {
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1); }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0); } }

.rc-slider-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.rc-slider-tooltip-hidden {
  display: none; }

.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px 0; }

.rc-slider-tooltip-inner {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 1rem;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9; }

.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid; }

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #6c6c6c; }

.primary-save-button {
  color: #fff;
  background: #00bbec 0% 0% no-repeat padding-box;
  border: 0;
  font-size: 0.625rem;
  outline: none;
  border-radius: 0.313rem;
  width: auto; }
  .primary-save-button:hover {
    background: #55dcff 0% 0% no-repeat padding-box; }
  .primary-save-button:disabled {
    background: #b1e4f1 0% 0% no-repeat padding-box; }
  .primary-save-button:focus {
    background: rgba(0, 121, 153, 0.3); }
  .primary-save-button:focus-within {
    background: rgba(0, 121, 153, 0.5);
    outline: none; }
  .primary-save-button:active {
    background: rgba(0, 121, 153, 0.7); }

.ifast-inner {
  width: 50%;
  margin-top: 40px; }
  .ifast-inner .col {
    margin-top: 20px; }
  .ifast-inner .save-btn {
    border: none;
    display: block;
    width: 50%;
    margin: auto;
    margin-top: 20px;
    font-size: 1rem;
    padding: 5px;
    color: white;
    background-color: #00aef3;
    transition: all 0.2s linear; }
    .ifast-inner .save-btn:hover {
      background-color: #7ed321; }

.checkbox-new div {
  align-items: center; }
  .checkbox-new div label {
    margin-left: 5px; }

textarea {
  outline: none;
  resize: none; }

.text-area-input {
  border: 1px solid #d6d6d6 !important;
  height: 150px; }

.v2-box-user {
  background: #0079b6 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  width: 260px;
  position: relative;
  overflow: hidden; }
  .v2-box-user .personIcon {
    position: absolute;
    bottom: 0px;
    right: -5px;
    width: 108px;
    height: 108px; }
  .v2-box-user .card-box {
    width: 100%;
    border-radius: 20px;
    box-sizing: border-box;
    height: 100%;
    background-size: cover; }
    .v2-box-user .card-box .banner {
      height: 186px;
      width: 100%;
      border-radius: 20px; }
      .v2-box-user .card-box .banner .images {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px; }
        .v2-box-user .card-box .banner .images img {
          height: 40px;
          cursor: pointer;
          object-fit: contain; }
  .v2-box-user .box-user-description-v2 {
    padding: 20px;
    padding-bottom: 5px; }
    .v2-box-user .box-user-description-v2 .details p {
      text-align: left;
      font: normal normal medium 13px/16px "inherit";
      letter-spacing: 0px;
      color: #ffffff;
      margin-bottom: 9px;
      opacity: 1;
      font-family: sans-serif !important;
      font-size: 13px !important; }
      .v2-box-user .box-user-description-v2 .details p .value {
        margin-left: 5px;
        font: normal normal normal 13px/16px "inherit";
        font-family: sans-serif !important; }
    .v2-box-user .box-user-description-v2 .details .clientName {
      font: normal normal 600 17px/20px "inherit"; }

.rc-tooltip-inner {
  background-color: white; }
  .rc-tooltip-inner .cover {
    padding-bottom: 0; }
    .rc-tooltip-inner .cover .menu-items {
      display: flex;
      align-items: center;
      margin-bottom: 26px;
      cursor: pointer;
      grid-gap: 12px;
      gap: 12px; }
      .rc-tooltip-inner .cover .menu-items:last-child {
        margin-bottom: 0; }
      .rc-tooltip-inner .cover .menu-items img,
      .rc-tooltip-inner .cover .menu-items .ico-arrow-right {
        width: 20px;
        height: 20px;
        object-fit: contain; }
      .rc-tooltip-inner .cover .menu-items .ico-arrow-right {
        color: black;
        display: flex;
        align-items: center;
        justify-content: space-between; }
      .rc-tooltip-inner .cover .menu-items svg {
        color: #505050;
        font-size: 20px; }
      .rc-tooltip-inner .cover .menu-items span {
        text-align: left;
        font: normal normal medium 16px/19px "inherit";
        letter-spacing: 0px;
        color: #505050;
        opacity: 1; }

.alphabet-svg-icon-wrapper-v1 {
  display: flex;
  align-items: center;
  border-radius: 10px;
  height: 45px;
  justify-content: center; }
  .alphabet-svg-icon-wrapper-v1 .alphabet-svg-icon-v1 {
    border: 1px solid #707070;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 0px 0px 10px;
    height: 100%;
    padding: 13px 8px; }
    .alphabet-svg-icon-wrapper-v1 .alphabet-svg-icon-v1 .alphabet-svg-icon-v1-blue {
      color: white;
      background-color: #00b0f0; }
    .alphabet-svg-icon-wrapper-v1 .alphabet-svg-icon-v1 .alphabet-svg-icon-v1-gray {
      background-color: white; }
  .alphabet-svg-icon-wrapper-v1 .alphabet-svg-icon-v2 {
    border-radius: 0px 10px 10px 0px;
    height: 100%;
    border-left: 0; }

.alphabet-box-v1 {
  justify-content: left;
  grid-gap: 20px;
  gap: 20px;
  padding-bottom: 20px;
  align-items: center; }

.alphabet-R-v1 {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 13px 0;
  position: relative;
  width: 400px;
  height: 45px; }
  .alphabet-R-v1 img {
    position: absolute;
    width: 19px;
    height: 19px;
    opacity: 0.75;
    left: 16px;
    top: 50%;
    opacity: 0.4;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }

.Search-v1 {
  width: 100%;
  padding-left: 40px;
  border: none;
  height: 100%;
  padding-right: 5px;
  text-align: left;
  font: normal normal normal 14px/18px "inherit";
  letter-spacing: 0px;
  color: #848484;
  font-family: sans-serif; }

.alphabetIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  flex-direction: row; }
  .alphabetIcons .outercover-v1 {
    padding: 13px 8px;
    border: 1px solid #cccccc;
    cursor: pointer;
    border-radius: 10px 0px 0px 10px; }
  .alphabetIcons .listView {
    background: #00b0f0 0% 0% no-repeat padding-box;
    color: white; }
  .alphabetIcons .gridView {
    color: white;
    background: #00b0f0 0% 0% no-repeat padding-box; }
  .alphabetIcons .outercover-v2 {
    border-radius: 0px 10px 10px 0px;
    border-left: 0; }
  .alphabetIcons .alphabet-item {
    width: 24px;
    height: 18px;
    cursor: pointer; }

.dashboard-admin .select-wrapper button {
  width: 264px !important;
  height: 45px;
  border: 1px solid #cccccc !important; }

.dashboard-admin .dashboard-card {
  grid-gap: 30px !important;
  gap: 30px !important; }

.tcb9e759d-27be-40d0-a454-86b8305aa402.place-right {
  opacity: 1 !important; }

.__react_component_tooltip.show {
  opacity: 1 !important; }

.header-arrow-v2 {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px; }

.topheaderpart-v1-inner {
  display: flex;
  padding: 17px;
  align-items: center;
  justify-content: space-between; }

.header-right-part {
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: space-between; }

.select-wrapper {
  width: 195px; }

.header-arrow-label-v2 {
  font: normal normal 600 18px/22px "inherit";
  letter-spacing: 0px;
  color: #505050;
  opacity: 1; }

.rc-tooltip {
  opacity: 1 !important; }

.navigateBack {
  background: white;
  color: black;
  padding: 10px;
  font-size: 16px;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px; }
  .navigateBack span {
    transition: all 2s ease-in-out; }
    .navigateBack span:hover {
      opacity: 0.9; }

.warn-msg {
  text-align: center;
  color: red;
  background: white;
  color: black;
  padding: 10px;
  font-size: 13px; }

.icon-tool {
  width: 20px;
  height: 20px; }

.subtitle-bar-recommendation {
  margin-top: -40px; }

.contentNetworth {
  display: flex;
  border: 1px solid red; }

.CircularProgressbar .CircularProgressbar-text {
  fill: #3e98c7;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle; }

.unsetWidth {
  width: 100% !important; }

.btn-inflation {
  font-size: 12px; }
  .btn-inflation:disabled {
    color: #5f5151 !important; }

.checkboxes-personal-dealings {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px; }
  .checkboxes-personal-dealings .wrapper-checkbox {
    display: flex;
    align-items: center;
    grid-gap: 2px;
    gap: 2px; }

.notfound-text {
  font-size: 65px; }

.disabled-div {
  pointer-events: none;
  opacity: 0.5; }

.cpd-info {
  margin-top: 16px; }

.cpd-history-text {
  display: block; }

.cpd-history-text::after {
  content: "";
  display: block;
  height: 3px;
  margin: 10px 0 0 0;
  position: relative;
  width: 50px;
  background: #7ed321 !important; }

.col-center {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }

.font-weight-bold {
  font-weight: 800; }

.filter-part-summary {
  display: flex;
  grid-gap: 50px 0;
  gap: 50px 0; }

.select-box-cover,
.filter-part-summary {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  justify-content: space-between;
  flex-direction: row; }

.filter-part-summary {
  grid-gap: 10px;
  gap: 10px;
  justify-content: flex-start;
  flex-wrap: wrap; }

.filter-part-summary .select-box-cover select {
  width: 200px !important; }

.w-20 {
  width: 50px !important; }

.fileName-selfdetermined {
  word-wrap: break-word; }

.life-insurance-dialog {
  display: flex;
  grid-gap: 30px;
  gap: 30px;
  position: relative;
  margin-top: 20px; }
  .life-insurance-dialog ul {
    margin-left: 30px;
    margin-right: 15px; }
  .life-insurance-dialog .react-tabs {
    width: 100%; }
  .life-insurance-dialog .react-tabs__tab {
    width: auto !important; }
  .life-insurance-dialog .first {
    width: 70%; }
  .life-insurance-dialog .second {
    width: 30%;
    margin-right: 15px; }
  .life-insurance-dialog .first-single {
    width: 100%;
    margin-bottom: 50px; }
  .life-insurance-dialog .inner {
    width: 100%; }

.lifeInsuranceDetails {
  height: 100%;
  position: relative; }
  .lifeInsuranceDetails .portal-form-row {
    width: 100% !important; }
  .lifeInsuranceDetails .content-panel-title_insurance {
    margin: 0;
    margin-top: 20px;
    width: 70%; }
    .lifeInsuranceDetails .content-panel-title_insurance .title-box {
      width: 250px;
      display: inline-block;
      text-align: center;
      padding: 8px 15px;
      margin: 0 20px 0 0;
      color: #ffffff;
      border-radius: 12px;
      background: #00aef3;
      border-bottom: 3px solid #e6e6e6; }

.life-insurance-bottom .content-panel-title_insurance {
  width: 100% !important; }
  .life-insurance-bottom .content-panel-title_insurance .title-box {
    width: 60% !important; }

.lifeInsuranceDetails-inner {
  position: relative; }

.custom-tabs-risk-history {
  margin-left: 0;
  padding-left: 0;
  left: 0; }
  .custom-tabs-risk-history .react-tabs__tab--selected {
    background-color: #7ed321;
    border-bottom: 0;
    color: white; }

.lifeInsuranceDetails .portal-form-row .row-holder {
  width: 250px; }

.classification-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px; }
  .classification-flex label {
    margin-right: 5px; }

.insurance-content-panel-header {
  text-align: center !important; }

.overview-box-red-insurance {
  background: #00aef3; }

#scenario-form {
  background-color: white;
  padding: 16px 24px; }
  #scenario-form .portal-form-row {
    margin-bottom: 0; }
  #scenario-form .row-scenario {
    margin-bottom: 20px; }

.z-index-1000 {
  z-index: 1000; }

.rc-slider-tooltip {
  z-index: 10000 !important; }

.slider-container .partner {
  margin-top: 30px; }

.finance_overview-panel-lifeline {
  margin-bottom: 10px;
  padding-bottom: 5px; }

.data-present {
  background-color: #7ed321 !important;
  color: white; }
  .data-present.ico-calendar::before {
    color: white; }

.gap-maintain {
  margin-right: 10px; }

.allocation-field-customize {
  display: flex;
  align-items: center; }
  .allocation-field-customize .percentage {
    width: 33% !important; }

.allocation-field .percentage {
  width: 33% !important;
  margin-right: 10px; }

.border-top {
  width: 100%;
  border-top: 1px solid white; }

.center-items {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px; }

.center-items-td {
  grid-gap: 30px;
  gap: 30px; }

.center-items-main {
  width: 160px; }

.select-box-w-100 {
  width: 120px; }

.hZzafa,
.sc-beqWaB {
  flex-direction: column;
  width: 150px !important; }

.spacing-before {
  width: 280px; }

.life-insurance-dialog .title-box-insurance {
  width: 250px !important;
  display: inline-block;
  text-align: center;
  padding: 8px 15px;
  margin: 0 20px 0 0;
  color: #ffffff;
  border-radius: 12px;
  background: #00aef3;
  border-bottom: 3px solid #e6e6e6;
  margin-right: 20px !important;
  text-align: center;
  display: flex;
  justify-content: center; }

.employment-income-src .row-holder {
  width: 100% !important; }

.GenericPortfolio {
  min-width: 850px !important; }

.bg-white {
  background-color: white; }

.risk-profile-scroll-form .react-tabs__tab-list {
  margin-bottom: 0;
  background: white;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1; }

.risk-profile-scroll-form .risk-profile_sticky {
  top: 41px;
  padding-top: 20px; }

.ekyc-form .portal-form-row label {
  width: 180px; }

.ekyc-form .portal-form-row .row-holder {
  width: 100%; }

.ekyc-middle-page {
  height: -webkit-calc(100vh - 180px);
  overflow-y: auto;
  overflow-x: hidden; }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.icon-refresh {
  position: relative;
  -webkit-animation: animate 1s linear infinite;
          animation: animate 1s linear infinite;
  cursor: pointer; }

.icon_refresh {
  cursor: pointer;
  width: 16px;
  height: 16px; }

@-webkit-keyframes animate {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes animate {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.kyc-middle-sections {
  grid-gap: 30px;
  gap: 30px; }

.portal-form-kycdetails {
  height: calc(100vh - 150px);
  overflow-y: auto; }

.start-title-box {
  width: 220px;
  flex-shrink: 0; }

.ekycc-only_available {
  font-size: 14px;
  font-weight: 500;
  color: red; }

.text-danger {
  color: red; }

.text-success {
  color: green; }

.text-pending {
  color: gray; }

.cs-scroll-box_investments .date {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center; }

.file-url {
  cursor: pointer;
  text-decoration: underline; }
  .file-url:hover {
    color: #00aef3;
    -webkit-text-decoration-color: #00aef3;
            text-decoration-color: #00aef3; }

.residence-insured-checkbox .row-holder:nth-child(2) {
  margin-top: 6px; }

.hidethis {
  display: none; }

.table-within_table th:first-of-type {
  border-radius: 0; }

.table-within_table th:last-of-type {
  border-radius: 0; }

.expand-icon {
  width: 16px;
  margin: 0 20px;
  height: 16px;
  flex-shrink: 0;
  cursor: pointer; }

.rotate {
  transition: all; }
  .rotate-upward {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  .rotate-downward {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }

.modal-investments {
  overflow: hidden !important; }
  .modal-investments #popup-investment-details {
    height: 400px;
    overflow: auto; }

.delete-doc {
  transition: all; }
  .delete-doc:hover {
    color: red; }

.table-report-mgmt th {
  width: unset !important; }

.table-report-mgmt td {
  text-align: left !important; }

.rename-report-row .row-holder {
  width: 100% !important; }

.coming-soon_text {
  color: #2b2b2b;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal; }

.table-kyc_status th {
  width: 200px;
  color: white; }
  .table-kyc_status th:nth-child(1) {
    background: #8ed34f; }
  .table-kyc_status th:nth-child(2) {
    background: #f9c228; }
  .table-kyc_status th:nth-child(3) {
    background: #ec602f; }

.table-kyc_status td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px; }

.total-line {
  width: 100%;
  height: 0.5px;
  background-color: black; }

.g-summary-box-line:hover {
  background: unset !important; }

.box-outer-kycc-report {
  padding: 10px;
  color: black;
  max-width: 300px;
  width: 100%;
  position: relative;
  height: 100px;
  border-radius: 8px;
  transition: 300 ease all; }
  .box-outer-kycc-report.item-approved {
    background-color: #8bc34a; }
  .box-outer-kycc-report.item-rejected {
    background-color: #f2972c; }
  .box-outer-kycc-report.item-cancelled {
    background-color: #eb522f; }
  .box-outer-kycc-report.item-readyforreview {
    background-color: #bebc3b; }
  .box-outer-kycc-report.item-inprogress {
    background-color: #38a9f4; }
  .box-outer-kycc-report:hover {
    opacity: 0.9; }
  .box-outer-kycc-report .label {
    text-align: center;
    height: 100%;
    font-weight: bold;
    font-size: 18px; }
  .box-outer-kycc-report p {
    position: absolute;
    left: 10px;
    bottom: 0;
    color: white;
    font-size: 14px; }

.table-lifestyle-education td:nth-child(1),
.table-lifestyle-education th:nth-child(1) {
  min-width: 200px; }

.table-lifestyle-education td:nth-child(2),
.table-lifestyle-education th:nth-child(2) {
  min-width: 200px; }

.table-lifestyle-education td:nth-child(3),
.table-lifestyle-education th:nth-child(3) {
  min-width: 200px; }

.table-lifestyle-education td:nth-child(4),
.table-lifestyle-education th:nth-child(4) {
  min-width: 150px; }

.table-lifestyle-education td:nth-child(5),
.table-lifestyle-education th:nth-child(5) {
  min-width: 150px; }

.table-lifestyle-education td:nth-child(7),
.table-lifestyle-education th:nth-child(7) {
  min-width: 50px; }

.table-lifestyle-education td:nth-child(8),
.table-lifestyle-education th:nth-child(8) {
  min-width: 100px; }

.table-lifestyle-education td:nth-child(9),
.table-lifestyle-education th:nth-child(9) {
  min-width: 100px; }

.table-lifestyle-education td:nth-child(10),
.table-lifestyle-education th:nth-child(10) {
  min-width: 80px; }

.table-lifestyle-education td:nth-child(12),
.table-lifestyle-education th:nth-child(12) {
  min-width: 100px; }

.scroll-form-module-mgmt {
  height: -webkit-calc(100vh - 150px); }

.scroll-form-user-mgmt {
  height: -webkit-calc(100vh - 100px) !important; }

.team-structure-bm .react-tabs__tab-list {
  position: -webkit-sticky;
  position: sticky;
  top: -12px;
  z-index: 10;
  background: white; }

.spacing-left {
  width: 200px;
  padding: 0 20px 0 0;
  vertical-align: middle; }

.portal-form-finance .cs-scroll-box {
  min-height: 250px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 5px; }

/* User Profile modal */
.user-profile .portal-form.portal-form-personalDetails {
  overflow-y: hidden; }

.user-profile .user-profile-formgroup > .row:not(:last-child) {
  margin-bottom: 10px; }

.user-profile .user-profile-formgroup > .row .col:first-of-type {
  flex-basis: 20%;
  flex-grow: initial; }

.user-profile .portal-form-businessDetails,
.user-profile .user-profile-formgroup,
.user-profile .address-row,
.user-profile .portal-form-personalDetails {
  padding: 20px 10px; }

.user-profile .portal-form-businessDetails {
  overflow-x: initial; }

.user-profile .portal-form-businessDetails > .row:not(:last-child) {
  margin-bottom: 10px; }

.user-profile .portal-form-businessDetails > .row .col:first-of-type {
  flex-basis: 25%;
  flex-grow: initial; }

.user-profile .address-row > .row:not(:last-child) {
  margin-bottom: 10px; }

.user-profile .address-row > .row .col:first-of-type {
  flex-basis: 25%;
  flex-grow: initial; }

.user-profile .portal-form-personalDetails > .row:not(:last-child) {
  margin-bottom: 10px; }

.user-profile .portal-form-personalDetails > .row .col:first-of-type {
  flex-basis: 25%;
  flex-grow: initial; }

.user-profile .kozai-system-form > .row:not(:last-child) {
  margin-bottom: 10px; }

.user-profile .kozai-system-form > .row .col:first-of-type {
  flex-basis: 25%;
  flex-grow: initial; }

.user-profile .react-tabs__tab-list li {
  padding-left: 10px !important;
  padding-right: 10px !important; }

.user-profile .portal-form-row label {
  flex-basis: 25%;
  flex-grow: initial; }

.user-profile .portal-form-row .row-holder {
  width: 100%; }
  .user-profile .portal-form-row .row-holder .user-profile-colorpicker {
    padding: 0; }

/* User Profile modal end.*/
.date-wrapper-dob {
  display: flex;
  align-items: center; }
  .date-wrapper-dob input {
    width: 90%;
    margin-right: 10px; }
  .date-wrapper-dob label {
    width: 8%;
    display: block;
    border: 1px solid #d6d6d6;
    padding: 5px;
    color: #000000;
    text-align: center; }
  .date-wrapper-dob .age {
    border: 1px solid #d6d6d6;
    padding: 5px;
    width: 30px; }

.personalDetailsForm {
  height: 100%;
  min-height: 100%; }

.portal-form.portal-form-personalDetails {
  overflow-x: hidden;
  min-height: 100%;
  overflow-y: scroll; }

.react-tabs__tab {
  height: initial;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 10px 13px; }

.custom_tabs {
  padding: 0; }

.content-wrap--spacebetween {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  align-items: center; }

.pr-13 {
  padding-right: 13px; }

.pt-6 {
  padding-top: 6px; }

.pb-20 {
  padding-bottom: 20px; }

.subtitle-bar {
  padding: 15px; }

.overviewicon-panel {
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 2px;
  gap: 2px; }
  .overviewicon-panel .icon-box {
    flex-basis: 88px;
    margin: 0;
    overflow: hidden;
    padding: 5px;
    min-height: 88px;
    border-radius: 8px;
    box-shadow: 0px 3px 15px rgba(189, 189, 189, 0.2); }
    .overviewicon-panel .icon-box .text {
      word-break: break-word;
      font-size: 13px; }

.chart-holder {
  display: flex;
  width: 100%;
  justify-content: space-between; }
  .chart-holder .box-full.box-info {
    flex-basis: 65%;
    width: 65%;
    border: none;
    padding-top: 25px; }
  .chart-holder .graph-panel {
    flex-basis: 30%;
    width: 30%; }

.graph-title {
  font-weight: 600;
  margin-bottom: 8px;
  text-transform: uppercase; }

.portal-maincontent.lifestylebuilder .portal-form {
  height: initial; }

.row-holder-other-accounts {
  border-bottom: none; }
  .row-holder-other-accounts label {
    width: 0;
    display: none; }

.btn-remove.account-no-remove {
  position: relative;
  right: 26px; }

.full-width {
  width: 100% !important; }

.pdf-icon {
  height: 60px;
  height: 60px;
  flex-shrink: 0;
  cursor: pointer; }

.lifestylebuilder #scroll-section {
  height: calc(100vh - 190px) !important; }

.rdw-editor-wrapper {
  min-height: 145px;
  box-sizing: border-box; }

.content-panel.finance {
  width: calc(100% - 101px);
  height: 100%;
  overflow: hidden; }

.portal-maincontent.finance .graph-panel--new {
  width: 100%;
  padding: 0;
  float: none;
  height: initial !important;
  overflow: initial; }
  .portal-maincontent.finance .graph-panel--new .graph-panel-tabs {
    width: inherit !important;
    display: flex;
    flex-wrap: wrap;
    overflow: initial;
    padding: 0; }
    .portal-maincontent.finance .graph-panel--new .graph-panel-tabs .cs-retirement-rating-box.overview-box-green {
      color: #2b2b2b;
      background: #fff;
      padding: 3px 0;
      flex-basis: 25%;
      margin: 0;
      word-break: break-word; }
      .portal-maincontent.finance .graph-panel--new .graph-panel-tabs .cs-retirement-rating-box.overview-box-green .title-redborder {
        text-align: left;
        color: inherit;
        font-size: 13px;
        text-transform: uppercase;
        padding: 0;
        margin: 0; }
      .portal-maincontent.finance .graph-panel--new .graph-panel-tabs .cs-retirement-rating-box.overview-box-green .rating {
        text-align: left;
        color: inherit;
        font: 800 14px "SourceSansProRegular", Arial, Helvetica, sans-serif;
        margin-bottom: 3px;
        color: #00b0f0; }

.portal-maincontent.finance .portal-form.portal-form-finance {
  height: calc(100vh - 255px);
  top: 0;
  margin-top: 20px !important;
  padding-right: 15px !important; }
  .portal-maincontent.finance .portal-form.portal-form-finance .react-tabs__tab-list {
    background-color: white;
    position: -webkit-sticky;
    position: sticky;
    top: -15px;
    z-index: 100; }

.content-panel.insuranceGap {
  width: -webkit-calc(100% - 100px); }

.portal-maincontent-insurance.insurance .portal-form-ekyc-insurance {
  height: -webkit-calc(100vh - 125px) !important; }

.portal-maincontent.cashflow .portal-form {
  height: -webkit-calc(100vh - 175px); }

.portal-maincontent.additional-information .portal-form {
  height: -webkit-calc(100vh - 145px); }

.lifeline #scroll-section {
  height: calc(100vh - 250px) !important; }

.financialRatios #scroll-section {
  height: calc(100vh - 185px) !important; }

.content-panel.cs-networth {
  height: initial;
  width: calc(100% - 100px); }
  .content-panel.cs-networth .portal-form {
    top: 0;
    height: calc(100vh - 190px) !important; }

.cs-scroll-box span {
  word-break: break-all; }

.content-panel.investment {
  width: calc(100% - 100px) !important; }
  .content-panel.investment .portal-form {
    top: 0;
    height: calc(100vh - 190px) !important; }

.content-panel.cs-cashflow {
  width: calc(100% - 100px) !important; }
  .content-panel.cs-cashflow .portal-form {
    top: 0;
    height: calc(100vh - 190px) !important; }

.portal-maincontent.retirement .content-panel.retirement .portal-form {
  top: 0;
  height: calc(100vh - 185px) !important; }

.portal-maincontent.retirement .graph-panel {
  height: 100% !important; }

.portal-maincontent.insuranceGap .content-panel .portal-form {
  top: 0;
  height: calc(100vh - 192px) !important; }

.portal-form.portal-form-dependantEducation {
  height: calc(100vh - 165px); }

select {
  outline: none; }

.content-panel-tab > span {
  height: initial;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 10px 16px;
  width: initial; }

.dashboard-content-panel.team-structure {
  overflow-x: initial;
  overflow-y: initial; }
  .dashboard-content-panel.team-structure > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .dashboard-content-panel.team-structure .content-panel.personaldetails {
    overflow-y: auto;
    overflow-x: auto;
    height: calc(100vh - 195px); }

.notification-dialog.investment-setting {
  min-height: 500px; }
  .notification-dialog.investment-setting .table-blue {
    border-radius: 8px; }
  .notification-dialog.investment-setting .table-blue tr td {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px; }
    .notification-dialog.investment-setting .table-blue tr td label {
      top: -2px;
      position: relative; }
    .notification-dialog.investment-setting .table-blue tr td .row {
      align-items: center; }

.invesement-setting-multiselect-wrap button:first-child {
  width: 240px;
  border: none !important;
  margin-left: auto; }

.invesement-setting-multiselect-wrap .investment-setting-multiselect {
  background-color: #fff;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  min-width: 240px; }

/*Sliding Sidebar.*/
:root {
  --sidebar-width: 220px;
  --header-bar-height: 40px; }

.portal-left {
  overflow-y: hidden;
  padding-left: 0;
  display: initial;
  padding-bottom: 0;
  width: 220px;
  width: var(--sidebar-width);
  transition: all 0.2s ease; }
  .portal-left::before {
    content: "";
    height: 100%;
    background: #fff;
    position: absolute;
    right: -1px;
    border-left: 1px solid #d6d6d6; }
  .lobby-container .portal-left::before {
    background-color: #f3f4fd; }
  .portal-left .arrow-sidebar-toggler {
    position: absolute;
    top: 10px;
    right: -12px;
    height: 24px;
    width: 24px;
    border-radius: 20000px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    background-color: #fff;
    box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
    border: transparent;
    transition: 0.2s ease; }
    .portal-left .arrow-sidebar-toggler svg {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      color: #44546f; }
    .portal-left .arrow-sidebar-toggler:hover {
      background-color: #00aef3; }
      .portal-left .arrow-sidebar-toggler:hover svg {
        color: #fff; }
  .portal-left .sidebar-navigation-wrap {
    overflow-y: auto;
    height: calc(100% - 85px);
    overflow-x: hidden;
    padding-bottom: 60px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column; }
  .portal-left nav.primary-menu ul li,
  .portal-left nav.primary-menu-end ul li {
    min-width: 193px; }
  .portal-left nav.primary-menu ul li a,
  .portal-left nav.primary-menu ul li span {
    padding-left: 15px; }

.sidebar-navigation-header {
  display: flex;
  align-items: center;
  padding: 12px 0;
  padding-left: 10px;
  width: 100%;
  position: relative;
  border-bottom: 1px solid #d6d6d6;
  justify-content: center; }
  .sidebar-navigation-header .hambIcon {
    position: static;
    background: #231f20;
    border-radius: 8px;
    height: 2px;
    width: 20px;
    -webkit-transform: initial;
            transform: initial; }
  .sidebar-navigation-header .hambIcon::after,
  .sidebar-navigation-header .hambIcon::before {
    border-radius: 8px;
    height: 2px;
    width: 20px;
    background: inherit;
    left: 9px; }
  .sidebar-navigation-header .hambIcon:after {
    top: 12px; }
  .sidebar-navigation-header .hambIcon:before {
    bottom: 12px; }

.portal-maincontent {
  width: calc(100% - 220px);
  width: calc(100% - var(--sidebar-width, 240px)); }

.header-bar {
  border-left: none;
  padding-left: 10px;
  min-height: 40px;
  min-height: var(--header-bar-height, 40px); }

#root .sidebar-inactive {
  --sidebar-width: 0; }
  #root .sidebar-inactive .sidebar-navigation-wrap {
    overflow-y: hidden; }
  #root .sidebar-inactive .sidebar-navigation-header {
    overflow: hidden; }
  #root .sidebar-inactive .portal-left {
    padding-right: 30px; }
    #root .sidebar-inactive .portal-left .arrow-sidebar-toggler svg {
      -webkit-transform: rotate(0);
              transform: rotate(0); }

.header-holder {
  float: none;
  width: 100%; }

.header-bar {
  justify-content: space-between;
  position: relative; }
  .header-bar__left {
    display: flex;
    align-items: center; }
  .header-bar button.hamb-holder {
    position: static;
    width: 42px;
    height: 32px;
    margin-right: 20px;
    border-radius: 4px;
    border: solid 1px transparent;
    box-shadow: 0 3px 6px rgba(71, 71, 71, 0.16), 0 3px 6px rgba(48, 48, 48, 0.23);
    background-color: #00b0f0; }
    .header-bar button.hamb-holder svg {
      fill: #ffffff; }
  .header-bar .hambIcon {
    position: static;
    background: #231f20;
    border-radius: 8px;
    height: 2px;
    width: 20px;
    -webkit-transform: initial;
            transform: initial; }

/*Sliding Sidebar End*/
.UserProfile .popup.popup-networth {
  overflow: initial; }

.PlanningClassification .portal-form-colsm-label {
  position: relative;
  padding-left: 15px; }
  .PlanningClassification .portal-form-colsm-label span.dot {
    position: absolute;
    top: 32%;
    left: 0; }
  .PlanningClassification .portal-form-colsm-label .dot-label {
    padding-left: 0; }

.rc-dropdown {
  width: 112px; }

.rc-dropdown-menu > .rc-dropdown-menu-item-selected:after {
  right: 12px !important; }

.show-dropdown {
  display: block;
  position: absolute;
  left: 0px;
  top: 50px;
  width: 155px;
  grid-gap: 12px;
  gap: 12px;
  border-radius: 4px;
  border: 1px solid #eee;
  z-index: 10;
  background: white; }
  .show-dropdown .language-item {
    border-bottom: 1px solid #eee;
    padding: 8px 12px; }
    .show-dropdown .language-item img {
      width: 28px;
      height: 18px;
      object-fit: cover; }
    .show-dropdown .language-item .lang {
      margin-left: 10px;
      color: #272d3b;
      font-size: 14px;
      font-weight: 400; }
      .show-dropdown .language-item .lang:hover {
        color: #00b0f0; }

.hide-dropdown {
  display: none; }

.investment-platform_overlay {
  width: 110px; }
  .investment-platform_overlay .language-item {
    padding: 8px;
    cursor: pointer; }

.hide-external-height {
  overflow: hidden; }

.table-spacing-above {
  margin-top: 200px; }

.no-spacing-above {
  margin-top: 10px; }

.primary-content {
  display: flex;
  height: 100%;
  grid-gap: 5px;
  gap: 5px; }
  .primary-content__left {
    width: calc(100% - 300px);
    flex-grow: 1;
    overflow: auto;
    height: 100%; }
  .primary-content__right {
    flex-basis: 300px; }

.reporting-currency {
  margin-bottom: 5px; }

.cashflow .portal-form {
  width: 100%; }
  .cashflow .portal-form .graph-panel-cashFlowGraphPanel {
    position: static;
    padding-top: 0;
    width: auto;
    float: none; }

.graph-panel-cashflow {
  position: static; }

/* Sidebar tooltip */
.portal-left {
  overflow: initial;
  z-index: 14; }

[data-toggle-tooltip] {
  position: relative; }

[data-toggle-tooltip]:hover::before {
  content: attr(data-toggle-tooltip);
  position: absolute;
  right: -100%;
  -webkit-transform: translateX(82%);
          transform: translateX(82%);
  background-color: #172b4d;
  color: #fff;
  padding: 0.5em;
  border-radius: 4px;
  width: -webkit-max-content;
  width: max-content;
  text-align: center; }

.portal-left .sidebar-navigation-wrap nav.primary-menu ul li:nth-of-type(1) a,
.portal-left
.sidebar-navigation-wrap
nav.primary-menu
ul
li:nth-of-type(1)
span {
  border-bottom: 1px solid #d6d6d6;
  border-right: 5px solid #ff0000;
  box-shadow: -5px 10px 13px -8px rgba(0, 0, 0, 0.15);
  margin-bottom: 20px; }

.portal-left nav.primary-menu ul li a,
.portal-left nav.primary-menu ul li span {
  word-wrap: break-word; }

.feedback-modal .popup {
  max-height: 590px;
  overflow: hidden; }
  .feedback-modal .popup .container-feedback {
    width: auto;
    max-width: 100%; }
  .feedback-modal .popup .portal-form-feedback {
    height: 450px;
    overflow: auto; }
  .feedback-modal .popup .feedback-content {
    overflow-y: initial;
    height: auto; }

.horizontal-separator {
  height: 0.5px;
  width: 100%;
  background-color: #d6d6d6;
  display: block; }

.goals-not-selected {
  background-color: #edf0f4;
  color: #606060; }
  .goals-not-selected:hover {
    background-color: #7ed321 !important; }

.goals-selected {
  background-color: #7ed321 !important;
  color: white; }

.ifast-form-field {
  display: flex;
  align-items: center;
  grid-gap: 16px;
  gap: 16px; }

.ifast-file-list {
  display: flex;
  align-items: center;
  grid-gap: 0px;
  gap: 0px; }
  .ifast-file-list .items {
    display: flex;
    align-items: center;
    grid-gap: 0px;
    gap: 0px;
    flex-wrap: wrap; }

.portal-form-ifast {
  height: calc(100vh - 98px);
  overflow-y: auto; }

.back-link {
  transition: all 0.3s ease-out; }
  .back-link:hover {
    opacity: 0.75; }

.super-annuation-fund .portal-form-half:nth-of-type(1) {
  width: 45%;
  padding: 0 10px 0 0 !important;
  display: inline-grid;
  position: relative; }

.super-annuation-fund .portal-form-half:nth-of-type(2) {
  width: 55%;
  padding: 0 10px 0 0 !important;
  display: inline-grid;
  position: relative; }

.usi-row-parent {
  position: relative; }
  .usi-row-parent .find-button-row-parent {
    left: 372px;
    position: absolute;
    top: 10px; }

.svg-icon-team-str {
  transition: all 0.3s ease;
  /* Smooth transition for the color change */ }
  .svg-icon-team-str:hover {
    opacity: 0.5; }

.currency-package-module {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 30px; }

.common-style-bm {
  height: -webkit-calc(100vh - 43px);
  padding: 0px !important;
  display: flex;
  flex-direction: column; }

.common-scroll-form {
  overflow-y: auto;
  overflow-x: auto;
  flex: 1 1;
  /* Makes the content section take up the remaining space */
  overflow-y: auto;
  /* Enables vertical scrolling */
  flex: 1 1;
  /* Makes the tabs content take up the remaining space */
  display: flex;
  flex-direction: column;
  padding-top: 10px 0; }

.portfolio-information-section {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  border: 1px solid #d6d6d6;
  border-radius: 8px;
  padding: 8px; }
  .portfolio-information-section .info-section {
    display: flex;
    flex-direction: column;
    grid-gap: 0px 8px;
    gap: 0px 8px; }
    .portfolio-information-section .info-section .label {
      font-weight: 500;
      font-size: 13px;
      word-wrap: break-word;
      height: 30px; }

.width-100 {
  width: 100% !important; }

.portfolio-stocks {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px; }
  .portfolio-stocks .key {
    width: 72px;
    display: flex;
    align-items: center;
    flex-direction: row;
    grid-gap: 4px;
    gap: 4px;
    height: 30px; }
  .portfolio-stocks .keys-section,
  .portfolio-stocks .values-section {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    flex-direction: column;
    justify-content: center; }
    .portfolio-stocks .keys-section .key-inner,
    .portfolio-stocks .values-section .key-inner {
      display: flex;
      align-items: center;
      grid-gap: 8px;
      gap: 8px;
      flex-direction: row; }
    .portfolio-stocks .keys-section .dot,
    .portfolio-stocks .values-section .dot {
      width: 16px;
      height: 16px;
      border-radius: 100%;
      flex-shrink: 0; }

.top-section-hidden {
  max-height: 0;
  transition: all 0.3s ease-in;
  overflow: hidden; }

.top-section-show {
  transition: all 0.3s ease-in;
  overflow-x: hidden; }

.space-adjust-tooltip {
  margin: 0;
  padding: 0; }

.green {
  color: #90c544; }

.portal-btn-submit-mr-10 {
  margin-right: 10px; }

.text-center {
  text-align: center; }

.retirement-info-card {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 16px;
  gap: 16px; }
  .retirement-info-card .overview-box-green {
    background-color: #90c544; }
  .retirement-info-card .overview-box-red {
    background-color: #f22e2e; }
  .retirement-info-card .overview-box-grey {
    background-color: #d1d1d1; }
  .retirement-info-card .rating {
    font-size: 14px; }
  .retirement-info-card .cs-retirement-rating-box {
    padding: 12px 20px; }

.insurance-gap-assessment {
  display: grid;
  grid-gap: 50px;
  gap: 50px;
  grid-template-columns: auto auto auto auto; }

.show-insurance-details:hover {
  color: #9d9d9d; }

/* Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 6px; }

/* Track */
::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.05); }

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.2);
  /* Subtle color */ }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background: rgba(0, 0, 0, 0.3); }

.action-panel {
  overflow-y: auto !important; }

.action-panel::-webkit-scrollbar {
  width: 6px; }

.insurance-renewal {
  display: flex;
  align-items: center;
  grid-gap: 36px;
  gap: 36px; }
  .insurance-renewal .label {
    font-size: 14px;
    width: 400px; }
  .insurance-renewal .value {
    font-size: 14px;
    text-decoration: underline;
    text-underline-offset: 2px; }

.filter-part-insurance-renewal {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 24px;
  gap: 24px; }

.insurance-renewal-card-infos {
  display: flex;
  grid-gap: 24px;
  gap: 24px;
  flex-wrap: wrap; }
  .insurance-renewal-card-infos .overview-box {
    width: 100%;
    max-width: 220px; }
    .insurance-renewal-card-infos .overview-box:hover::after {
      background: white; }

.remarks-text-area-outer {
  border: 1px solid #d6d6d6;
  outline: none; }

.w-full {
  width: 100% !important; }

.sharepoint .navigations {
  font-size: 20px; }
  .sharepoint .navigations span {
    cursor: pointer; }

.sharepoint .sortBy {
  margin: 20px 0; }
  .sharepoint .sortBy span {
    font-size: 16px;
    margin-right: 5px;
    display: block; }
  .sharepoint .sortBy svg {
    cursor: pointer; }

.sharepoint .inner-item {
  margin: 0 20px 20px 0;
  padding: 15px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  border: 1px solid #e6dbdb; }
  .sharepoint .inner-item:hover {
    border: 1px solid #c5baba; }
  .sharepoint .inner-item .dots svg {
    position: absolute;
    right: 0;
    top: 5px;
    height: 20px;
    width: 20px;
    object-fit: cover; }
  .sharepoint .inner-item img {
    width: 130px;
    height: 130px;
    object-fit: contain; }
  .sharepoint .inner-item .name {
    margin-top: 10px;
    font-size: 18px;
    text-align: center;
    word-wrap: break-word; }

.download-btn_sharepoint {
  color: black;
  padding: 5px; }
  .download-btn_sharepoint svg {
    width: 18px;
    cursor: pointer;
    height: 18px; }

.sharepoint .navigations {
  font-size: 20px; }
  .sharepoint .navigations span {
    cursor: pointer; }

.sharepoint .sortBy {
  margin: 20px 0; }
  .sharepoint .sortBy span {
    font-size: 16px;
    margin-right: 5px;
    display: block; }
  .sharepoint .sortBy svg {
    cursor: pointer; }

.sharepoint .inner-item {
  margin: 0 20px 20px 0;
  padding: 15px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  border: 1px solid #e6dbdb; }
  .sharepoint .inner-item:hover {
    border: 1px solid #c5baba; }
  .sharepoint .inner-item .dots svg {
    position: absolute;
    right: 0;
    top: 5px;
    height: 20px;
    width: 20px;
    object-fit: cover; }
  .sharepoint .inner-item img {
    width: 130px;
    height: 130px;
    object-fit: contain; }
  .sharepoint .inner-item .name {
    margin-top: 10px;
    font-size: 18px;
    text-align: center;
    word-wrap: break-word; }

.download-btn_sharepoint {
  color: black;
  padding: 5px; }
  .download-btn_sharepoint svg {
    width: 18px;
    cursor: pointer;
    height: 18px; }

.login-outer_container {
  height: 100vh;
  overflow: auto; }

.login-container {
  height: auto;
  height: 100%; }
  .login-container .loginForm {
    height: 100%; }
  .login-container .login-left {
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    height: 100%; }
    .login-container .login-left .lorem-text {
      color: #fefefe;
      margin-top: 24px;
      font-size: 24px;
      font-weight: 700; }
    .login-container .login-left .info-text {
      margin-top: 16px;
      font-size: 16px;
      font-weight: 400;
      color: #d5f4ff; }
    .login-container .login-left .logo {
      width: 500px;
      height: 300px;
      flex-shrink: 0; }
      @media only screen and (max-width: 1200px) {
        .login-container .login-left .logo {
          width: 400px;
          height: 200px; } }
    .login-container .login-left .version {
      position: absolute;
      left: 55px;
      bottom: 60px;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      color: white; }
  .login-container .login-right {
    background-color: #fbfbff; }
  .login-container .language-selector .languages-container .language-current_item {
    cursor: pointer; }
  .login-container .language-selector .languages-container .language-current_item {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 12px;
    margin-left: auto;
    border-radius: 4px;
    border: 1px solid #eee;
    position: relative; }
    .login-container .language-selector .languages-container .language-current_item img {
      width: 28px;
      height: 18px;
      object-fit: cover; }
    .login-container .language-selector .languages-container .language-current_item .lang {
      margin-left: 10px;
      color: #272d3b;
      font-size: 16px;
      font-weight: 400; }
    .login-container .language-selector .languages-container .language-current_item .down-icon {
      margin-left: 21px;
      width: 20px;
      height: 20px;
      color: #505050;
      transition: all; }
      .login-container .language-selector .languages-container .language-current_item .down-icon.rotate-180 {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg); }
  .login-container .language-selector .languages-container .show-dropdown {
    display: block;
    position: absolute;
    left: 0px;
    top: 50px;
    width: 155px;
    grid-gap: 12px;
    gap: 12px;
    border-radius: 4px;
    border: 1px solid #eee;
    z-index: 10;
    background: white; }
    .login-container .language-selector .languages-container .show-dropdown .language-item {
      border-bottom: 1px solid #eee;
      padding: 8px 12px; }
      .login-container .language-selector .languages-container .show-dropdown .language-item img {
        width: 28px;
        height: 18px;
        object-fit: cover; }
      .login-container .language-selector .languages-container .show-dropdown .language-item .lang {
        margin-left: 10px;
        color: #272d3b;
        font-size: 14px;
        font-weight: 400; }
        .login-container .language-selector .languages-container .show-dropdown .language-item .lang:hover {
          color: #00b0f0; }
  .login-container .language-selector .languages-container .hide-dropdown {
    display: none; }
  .login-container .login-title {
    font-size: 32px;
    font-weight: 700;
    color: #2b2b2b;
    text-align: center;
    margin-top: 40px; }
  .login-container .steps {
    margin-top: 48px; }
    .login-container .steps .inner-step {
      width: 58px;
      /* Adjusted width to include the border */
      height: 58px;
      /* Adjusted height to include the border */
      border-radius: 50%;
      border: 1px solid #bfbfbf;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      box-sizing: border-box;
      /* Include padding and border in the total width and height */ }
      .login-container .steps .inner-step.active {
        border: 1px solid #00b0f0; }
      .login-container .steps .inner-step.active-bg {
        background-color: #00b0f0; }
      .login-container .steps .inner-step .content {
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        color: #848484;
        margin-left: -5px; }
        .login-container .steps .inner-step .content.active {
          color: #00b0f0; }
        .login-container .steps .inner-step .content.active-text {
          color: white; }
    .login-container .steps .step-type {
      position: absolute;
      bottom: -25px;
      left: 50%;
      -webkit-transform: translate(-50%);
              transform: translate(-50%);
      font-size: 14px;
      font-weight: 600; }
      .login-container .steps .step-type.active {
        color: #00b0f0; }
    .login-container .steps .st-line {
      width: 135px;
      height: 4px;
      background-color: #eeeeee; }
      .login-container .steps .st-line.active {
        background-color: #00b0f0; }

.form-fields {
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
  max-width: 444px;
  margin-left: auto;
  margin-right: auto; }

.row-holder-outer {
  width: 100%;
  align-items: flex-start;
  justify-content: center; }
  .row-holder-outer label {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: #848484;
    margin-bottom: 8px; }
  .row-holder-outer .row-holder-inner {
    width: 100%;
    position: relative; }
  .row-holder-outer input,
  .row-holder-outer select {
    width: 100%;
    border: 1px solid #bfbfbf;
    padding: 14px 10px;
    outline: none;
    border-radius: 4px;
    color: #2b2b2b;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px; }
    .row-holder-outer input.is-invalid,
    .row-holder-outer select.is-invalid {
      border-radius: 0px;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px; }
    .row-holder-outer input::-webkit-input-placeholder, .row-holder-outer select::-webkit-input-placeholder {
      color: #848484; }
    .row-holder-outer input::placeholder,
    .row-holder-outer select::placeholder {
      color: #848484; }
  .row-holder-outer select {
    background-position: calc(100% - 1rem) center;
    -webkit-appearance: none;
    appearance: none;
    background-repeat: no-repeat; }
  .row-holder-outer .password-toggle {
    position: absolute;
    right: 16px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); }
    .row-holder-outer .password-toggle.eye-icon {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      cursor: pointer; }
    .row-holder-outer .password-toggle.push-upward {
      top: calc(50% - 18px);
      -webkit-transform: translate(0);
              transform: translate(0); }

.btn-login {
  color: white;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  border: 0;
  border-radius: 4px;
  background: #00b0f0;
  padding: 14px 40px;
  width: 100%;
  display: block;
  box-shadow: 0px 4px 16px 0px rgba(0, 176, 240, 0.4);
  transition: all; }
  .btn-login:hover {
    background: #8ee7ff;
    box-shadow: 0px 4px 16px 0px rgba(0, 176, 240, 0.4); }

.forgot-password {
  width: 100%; }
  .forgot-password label {
    color: #505050;
    font-size: 16px;
    font-weight: 500; }
  .forgot-password .text-content {
    color: #00b0f0;
    text-align: right;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer; }

.logos {
  grid-gap: 20px;
  gap: 20px;
  padding: 40px 0;
  position: relative; }
  @media only screen and (min-width: 1600px) {
    .logos {
      position: absolute;
      left: 50%;
      bottom: 0;
      -webkit-transform: translate(-50%, 0);
              transform: translate(-50%, 0); } }
  .logos :nth-child(1) {
    width: 182px;
    height: 35px;
    flex-shrink: 0; }
  .logos :nth-child(2) {
    width: 105px;
    height: 34px;
    flex-shrink: 0; }

.entities {
  margin: 40px 0 110px 0;
  width: 100%; }

.remember-me_outer label {
  color: #505050;
  font-size: 16px;
  font-weight: 500; }

html,
body,
#root {
  height: 100%; }

:root {
  --action-panel-width: 100px; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.h-100 {
  height: 100%; }

h4 {
  text-align: left;
  font: 600 1.125rem/1.313rem Inter;
  letter-spacing: 0px;
  color: #373b46; }

label {
  text-align: left;
  font: 500 0.813rem/1rem Inter;
  letter-spacing: -0.26px;
  color: #606060; }

.input-login {
  font: 500 0.813rem/1.25rem Inter;
  letter-spacing: 0px;
  color: #373b46;
  border: 0;
  border-bottom: 0.25rem solid #d3dfed;
  border-radius: unset;
  background-color: transparent !important;
  box-shadow: none; }
  .input-login:active {
    border: 0;
    border-bottom: 0.25rem solid #6690c1; }
  .input-login:hover {
    border: 0;
    border-bottom: 0.25rem solid #8baacf; }
  .input-login:focus {
    border: 0;
    border-bottom: 0.25rem solid #4776ad;
    box-shadow: none; }

.login-body {
  background: transparent url(https://koizai.com/app/static/media/LighterBG.83b558bd.jpg) no-repeat center center fixed;
  background-size: cover; }

.forgot-password-link {
  text-decoration: underline;
  font: 500 0.813em/1em Inter;
  letter-spacing: -0.26px;
  color: #606060; }
  .forgot-password-link:hover {
    color: #606060;
    font: 600 0.813em/1em Inter; }

.form-check-input {
  margin-top: 0 !important;
  background: #f0f0f0 0% 0% no-repeat padding-box;
  border-radius: 1em;
  font: 500 0.813rem/1rem Inter; }

.loginBg-leftSide {
  background: url(https://koizai.com/app/static/media/Pattern-ZenGarden-500px.a79d6fff.png);
  background-size: cover;
  background-repeat: no-repeat; }
  .loginBg-leftSide .logo {
    width: 55%; }

.loginBg-rigthSide {
  background-color: #fff; }

.client-menu-content {
  background-size: cover;
  height: 100vh;
  width: 100vw;
  background-color: #ebebeb; }

.dashboard-adviser,
.lobby-container {
  height: 100%; }

.new-client-form {
  background: #f3f4fd;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 60px; }
  .new-client-form .lobby-card {
    width: 100%;
    max-width: 240px; }

.search-client-inputgroup {
  border: 1px solid #dddbdb;
  border-radius: 4px;
  height: 3rem; }
  .search-client-inputgroup div,
  .search-client-inputgroup span,
  .search-client-inputgroup input {
    background: #fff 0% 0% no-repeat padding-box;
    height: 3rem; }
    .search-client-inputgroup div:active,
    .search-client-inputgroup span:active,
    .search-client-inputgroup input:active {
      border: 0;
      box-shadow: none; }
    .search-client-inputgroup div:hover,
    .search-client-inputgroup span:hover,
    .search-client-inputgroup input:hover {
      border: 0;
      box-shadow: none; }
    .search-client-inputgroup div:focus,
    .search-client-inputgroup span:focus,
    .search-client-inputgroup input:focus {
      border: 0;
      box-shadow: none; }
  .search-client-inputgroup input {
    font: 600 0.8125rem/1.25rem Inter;
    letter-spacing: 0px;
    color: #373b46; }
  .search-client-inputgroup i {
    color: #707070; }

.client-option .client-option-card {
  box-shadow: 0px 1px 3px #00000029;
  text-decoration: none;
  color: #373b46;
  background: white;
  border-radius: 10px; }
  .client-option .client-option-card:hover {
    text-decoration: none;
    background: #00b0f0 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px #00000029; }
    .client-option .client-option-card:hover .newClient-icon {
      background: url(https://koizai.com/app/static/media/add-new-user-hover.30460e6b.svg);
      background-size: contain;
      background-repeat: no-repeat;
      height: 5rem;
      width: 6rem;
      background-position: center; }
    .client-option .client-option-card:hover .clientManagement-icon {
      background: url(https://koizai.com/app/static/media/client-management-hover.7bf04b6e.svg);
      background-size: contain;
      background-repeat: no-repeat;
      height: 5rem;
      width: 6rem;
      background-position: center; }
    .client-option .client-option-card:hover .demoMode-icon {
      background: url(https://koizai.com/app/static/media/demo-mode-hover.f96e0616.svg);
      background-size: contain;
      background-repeat: no-repeat;
      height: 5rem;
      width: 6rem;
      background-position: center; }
    .client-option .client-option-card:hover .businessManagement-icon {
      background: url(https://koizai.com/app/static/media/business-management-hover.1a1d275c.svg);
      background-size: contain;
      background-repeat: no-repeat;
      height: 5rem;
      width: 6rem;
      background-position: center; }
  .client-option .client-option-card.disabled {
    cursor: not-allowed; }
    .client-option .client-option-card.disabled:hover {
      background-color: #fff;
      box-shadow: none;
      border: none; }
    .client-option .client-option-card.disabled .newClient-icon {
      background: url(https://koizai.com/app/static/media/add-new-user-disabled.7eb76fe2.svg);
      background-size: contain;
      background-repeat: no-repeat;
      height: 5rem;
      width: 6rem;
      background-position: center; }
    .client-option .client-option-card.disabled .clientManagement-icon {
      background: url(https://koizai.com/app/static/media/client-management-disabled.886b5c27.svg);
      background-size: contain;
      background-repeat: no-repeat;
      height: 5rem;
      width: 6rem;
      background-position: center; }
    .client-option .client-option-card.disabled .demoMode-icon {
      background: url(https://koizai.com/app/static/media/demo-mode-disabled.75dfefba.svg);
      background-size: contain;
      background-repeat: no-repeat;
      height: 5rem;
      width: 6rem;
      background-position: center; }
    .client-option .client-option-card.disabled .businessManagement-icon {
      background: url(https://koizai.com/app/static/media/business-management-disabled.2d9404ad.svg);
      background-size: contain;
      background-repeat: no-repeat;
      height: 5rem;
      width: 6rem;
      background-position: center; }

.client-option .client-option-label {
  text-align: center;
  font: 600 1.125rem/1.3125rem Inter;
  letter-spacing: 0px;
  color: #373b46; }

.client-option .newClient-icon {
  background: url(https://koizai.com/app/static/media/add-new-user-active.be7e9eed.svg);
  background-size: contain;
  background-repeat: no-repeat;
  height: 5rem;
  width: 6rem;
  background-position: center; }

.client-option .clientManagement-icon {
  background: url(https://koizai.com/app/static/media/client-management-active.b51eb5a3.svg);
  background-size: contain;
  background-repeat: no-repeat;
  height: 5rem;
  width: 6rem;
  background-position: center; }

.client-option .demoMode-icon {
  background: url(https://koizai.com/app/static/media/demo-mode-active.9b709c44.svg);
  background-size: contain;
  background-repeat: no-repeat;
  height: 5rem;
  width: 6rem;
  background-position: center; }

.client-option .businessManagement-icon {
  background: url(https://koizai.com/app/static/media/business-management-active.1032f535.svg);
  background-size: contain;
  background-repeat: no-repeat;
  height: 5rem;
  width: 6rem;
  background-position: center; }

.percentage-wrapper {
  margin-top: -25px; }

.goals .percentage-wrapper {
  margin-top: 0; }

#percent-sign {
  top: 25px;
  left: 45px;
  color: #555;
  position: relative;
  z-index: 1; }

.termAndCondition .declaration {
  padding-bottom: 20px; }

.retirementGoal-popup .remove-button-wrapper {
  float: right;
  top: 50px;
  padding-left: 10px; }

.termAndCondition .adviser-declaration-col8 .malabar-date {
  width: 230px; }

.termAndCondition .client-column {
  max-width: 260px !important; }

.termAndCondition .client-column-header {
  max-width: 260px !important;
  padding-left: 30px;
  padding-right: 0; }

.retirementGoal-popup .retirement-goal-addrow-income {
  width: 150px; }

.retirementGoal-popup .retirement-goal-addrow-index {
  width: 10px; }

.retirementGoal-popup .retirement-goal-addrow-year {
  width: 150px; }

.retirementGoal-popup .retirement-goal-addrow-remove {
  text-align: center; }

.antimoneylaundry-wrapper {
  padding-top: 30px; }

.additional-information-wrapper {
  width: 1000px; }

.additional-information-wrapper .tabs {
  width: 300px; }

.supplemental-action-button-selected {
  display: inline-block;
  vertical-align: top;
  background: #548235 !important;
  margin: 0 14px 14px 0;
  cursor: pointer; }

.supplemental-action-button {
  display: inline-block;
  vertical-align: top;
  background: #efefef;
  margin: 0 14px 14px 0;
  cursor: pointer; }

.dashboard-content-panel .import-client-row .importClientsDialog {
  padding-right: 10px; }

.cs-refresh-button {
  position: absolute;
  right: 120px;
  top: 125px;
  z-index: 10; }

.FxRates .go-back-row {
  padding-top: 30px; }

.termAndCondition .portal-btn-submit {
  background-color: #7ed321;
  font-size: 20px;
  width: 200px; }

.termAndCondition .portal-btn-submit-disabled {
  background-color: #cccccc !important;
  font-size: 20px;
  width: 200px; }

.termAndCondition .TermAndCondition-submission-date {
  font-weight: bold;
  padding-left: 30px; }

.termAndCondition .termAndCondition-row {
  padding-top: 30px; }

.LiquityRatioDetails-row {
  font-size: 14px; }

.flex-container {
  display: flex !important;
  flex-wrap: nowrap; }

.flex-container .col {
  width: 50%;
  font-size: 14px;
  text-align: left; }

.flex-container .col-right {
  width: 50%;
  margin: 10px;
  text-align: right;
  font-size: 14px; }

.popup-LiquityRatioDetails {
  width: 600px; }

.liquid-ratio-text {
  font-weight: bold;
  font-size: 20px; }

.select-box-investmentplatform {
  width: 100% !important; }

#unitTrustForm .addnew-btn-holder {
  padding-top: 5px;
  display: flex; }

#unitTrustForm .addnew-btn-holder .btn-plus {
  width: 44px; }

#unitTrustForm .btn-plus:hover {
  background: #9a9a9a;
  color: #ffffff !important; }

#unitTrustForm .newipnamedisabled {
  pointer-events: none; }

#unitTrustForm .row-holder-addNew {
  width: 100% !important; }

#unitTrustForm .portal-form-row-others {
  align-items: flex-start; }

.UnderMaintenance .undermaintenance-header {
  text-align: center;
  padding-top: 5%; }

.UnderMaintenance .undermaintenance-header label {
  font-weight: bold;
  font-size: 30pt;
  text-align: center; }

.UnderMaintenance .undermaintenance-txt {
  padding-top: 20px;
  text-align: center; }

.SuperannuationFund {
  background-color: white !important; }

#superannuationFund .container-fluid-networth-form {
  background-color: white !important;
  height: 100% !important; }

#superannuationFund .portal-form-popup .superannuation-section {
  padding-top: 30px; }

#superannuationFund .blank-hr {
  padding-top: 30px; }

#superannuationFund .usi-row .row-holder .text-input {
  width: 70% !important; }

.UsiDialog .usi-row .row-holder .text-input {
  width: 80% !important; }

#superannuationFund .employment-row {
  position: relative; }

#superannuationFund .employment-row .row-holder .text-input {
  width: 90% !important; }

#superannuationFund .employmentIncomeSource-row {
  width: 90% !important; }

#superannuationFund .employmentIncomeSource-row label {
  margin-right: 20px !important; }

#superannuationFund .find-button-row {
  position: relative;
  top: -35px;
  left: 415px; }

#superannuationFund .find-button-disabled-true {
  pointer-events: none;
  opacity: 0.4; }

.UsiDialog .find-button-row {
  position: relative;
  top: -35px;
  left: 850px; }

#superannuationFund .employment-button-row {
  position: absolute;
  top: 5px;
  right: 10px; }

#superannuationFund .table-row {
  margin-left: 100px;
  padding-bottom: 30px;
  padding-top: 30px; }

#superannuationFund table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%; }

#superannuationFund td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px; }

#superannuationFund tr:nth-child(even) {
  background-color: #dddddd; }

#superannuationFund .table-wrapper {
  position: initial;
  width: 100%;
  padding-top: 20px; }

#superannuationFund .left-panel {
  width: 50% !important;
  padding-right: 10px;
  padding-bottom: 10px; }

#superannuationFund .iDontHaveUSI {
  padding-bottom: 30px; }

#superannuationFund .iDontHaveUSI .row-holder {
  position: relative; }

#superannuationFund .find-button {
  width: 60px;
  display: inline-block;
  text-align: center;
  padding: 8px 15px;
  color: #ffffff;
  border-radius: 12px;
  background: #00aef3;
  border-bottom: 3px solid #e6e6e6;
  margin-top: -5px;
  margin-left: -25px; }

#superannuationFund .contributionsRestriction {
  padding-top: 10px; }

#superannuationFund .tfn {
  padding-top: 10px; }

#superannuationFund .left-panel .subtitle2 {
  padding-bottom: 30px !important; }

#superannuationFund .left-panel-2 {
  width: 50% !important;
  position: absolute;
  left: 0;
  padding-right: 10px;
  padding-top: 30px; }

#superannuationFund .left-panel-3 {
  width: 50% !important;
  position: absolute;
  left: 0;
  top: 705px;
  padding-right: 10px;
  padding-bottom: 100px;
  padding-top: 140px; }

#superannuationFund .left-panel-1-insurance {
  width: 50% !important;
  position: absolute;
  left: 0;
  top: 150px;
  padding-right: 10px;
  padding-bottom: 100px; }

#superannuationFund .left-panel-1-insurance-benefit {
  width: 50% !important;
  position: absolute;
  left: 0;
  padding-right: 10px;
  padding-bottom: 100px;
  top: 300px; }

.SuperAnnuationDialog .left-panel-investment {
  width: 50% !important;
  position: absolute;
  left: 0;
  padding-right: 10px;
  padding-bottom: 100px;
  padding-left: 30px; }

#superannuationFund .right-panel {
  width: 50% !important;
  position: absolute;
  right: -15px;
  top: 247px;
  padding-top: 0; }

#superannuationFund .right-panel-2 {
  width: 50% !important;
  position: absolute;
  right: -15px;
  top: 470px;
  padding-top: 140px; }

#superannuationFund .right-panel-3 {
  width: 50% !important;
  position: absolute;
  right: -15px;
  top: 650px;
  padding-bottom: 100px;
  padding-top: 140px; }

#superannuationFund .right-panel-3-insurance {
  width: 50% !important;
  position: absolute;
  right: -15px;
  top: 150px;
  padding-bottom: 100px; }

.SuperAnnuationDialog .right-panel-investment {
  width: 45% !important;
  position: absolute;
  right: 20px;
  padding-bottom: 100px;
  padding-right: 25px;
  top: 155px; }

.SuperAnnuationDialog .left-panel-1-investment {
  width: 50% !important;
  position: absolute;
  left: 0;
  top: 150px;
  padding-right: 10px;
  padding-bottom: 100px;
  padding-left: 25px; }

.SuperannuationFund .page-title {
  top: -1px !important; }

.SuperannuationFund .NetWorthFormButtons {
  padding-right: 30px !important; }

.SuperannuationFund .tab1 {
  padding-top: 5px; }

#SuperannuationFund .table-row-investment {
  width: 100%; }

#superannuationFund .investment-plus {
  text-align: right;
  padding-bottom: 20px; }

#SuperannuationFund .investment-button-row {
  padding-bottom: 30px; }

.UsiDialog {
  width: 1000px !important;
  background-color: white !important; }

.SuperAnnuationDialog {
  width: 1000px !important;
  background-color: white !important; }

.SuperAnnuationDialog .valudationDate-row {
  height: 46px; }

.SuperAnnuationDialog .date-wrapper {
  positiion: relative; }

.SuperAnnuationDialog .datepicker-container {
  top: -17px;
  position: relative;
  width: 58%;
  left: 196px; }

.SuperAnnuationDialog .purchaseDate-date-wrapper .datepicker-container {
  top: -17px;
  position: relative;
  width: 58%;
  left: 170px !important; }

.SuperAnnuationDialog .investment-button {
  text-align: right;
  padding-top: 50px; }

.SuperAnnuationDialog .input-row {
  width: 100%;
  margin-left: 70px; }

.UsiDialog .table-row {
  padding-bottom: 30px;
  padding-top: 30px; }

.UsiDialog table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%; }

.UsiDialog td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px; }

.UsiDialog tr:nth-child(even) {
  background-color: #dddddd; }

.NetWorthFormButtons {
  position: absolute; }

.lifeInsuranceDetails {
  background-color: #ffffff; }

.lifeInsuranceDetails .reporting-currency {
  padding-left: 30px; }

.lifeInsuranceDetails .portal-form-twothird {
  padding-left: 30px; }

.lifeInsuranceDetails .container-fluid {
  height: 100% !important; }

.lifeInsuranceDetails .flexbox {
  height: 100% !important; }

.GenericRiskProfile {
  width: 100% !important; }

.GenericRiskProfile .portal-form-row2 .row-holder2 {
  text-align: center; }

.GenericRiskProfile .portal-form-row2 .row-holder2 .text-input {
  text-align: center;
  margin-left: 10px; }

.GenericRiskProfile .answer-row .typeRiskDefinition {
  margin-left: 15px;
  height: 50px; }

.react-checkbox-tree {
  border: 1px solid black !important; }

.terms-conditions-content {
  width: 100%; }
  .terms-conditions-content .detail-intro_section {
    margin-bottom: 40px; }
    .terms-conditions-content .detail-intro_section .title-point {
      margin-bottom: 36px;
      font: normal normal bold 24px/31px "Montserrat";
      color: #505050;
      position: relative;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      grid-gap: 30px;
      gap: 30px; }
    .terms-conditions-content .detail-intro_section .point {
      font: normal normal normal 16px/31px "Montserrat";
      color: #505050; }

.accept-decline_section {
  display: flex;
  grid-gap: 30px;
  gap: 30px;
  align-items: center;
  justify-content: center; }
  .accept-decline_section button {
    border: none;
    width: 166px;
    height: 45px;
    color: white;
    font-size: 16px;
    text-align: center;
    border-radius: 10px; }
  .accept-decline_section .accept-btn {
    background-color: #00b0f0;
    color: white; }
  .accept-decline_section .decline-btn {
    background-color: white;
    color: #00b0f0;
    border: 1px solid #00b0f0; }

.popup-template-modal {
  height: auto; }

.template-name-textbox {
  border: 1px solid #d6d6d6;
  border-radius: 2px;
  width: 100%;
  height: 2rem; }

.center-save-template-button {
  text-align: center; }

.displayNone{
    display: none;
}

.loadingMask {
    position: fixed;
    z-index: 1051;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: #444444;
    opacity: 0.7;
}

.panelLoad {
    z-index: 1002;
    margin-left: -20px !important;
    margin-top: -35px !important;
    -webkit-animation: panel 2.2s infinite forwards !important;
    animation: panel 2.2s infinite forwards !important;
}

.panel-spinner {
    margin: 0 auto !important;
    /* position: absolute !important; */
    left: 50% !important;
    top: 50% !important;
}

.loadingSpinner{
    position:absolute;
    left:50%;
    top:50%;
}

.circle {
  -webkit-animation:spin 3s linear infinite both;
          animation:spin 3s linear infinite both;
  background:#AFD000;
  border-radius:100vmax;
  /* margin:calc(50vh - 5vmin) calc(50vw - 1vmin); */ /* Half the longest viewport width minus half the width/height of the circle. */
  margin:60vh calc(50vw - 1vmin);
  height:5vmin;
  position:absolute;
  width:5vmin;
}
.circle + .circle {
  -webkit-animation:spin 3s linear 1s infinite both;
          animation:spin 3s linear 1s infinite both;
  background:#AFD000;
}
.circle + .circle + .circle {
  -webkit-animation:spin 3s linear 2s infinite both;
          animation:spin 3s linear 2s infinite both;
  background:#AFD000;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(360deg) translate(0vmax,-10vmax);
            transform: rotate(360deg) translate(0vmax,-10vmax);
	}
  50% {
    -webkit-transform: rotate(180deg) translate(0vmax,0vmax);
            transform: rotate(180deg) translate(0vmax,0vmax);
  }
	100% {
    -webkit-transform: rotate(0deg) translate(0vmax,-10vmax);
            transform: rotate(0deg) translate(0vmax,-10vmax);
	}
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(360deg) translate(0vmax,-10vmax);
            transform: rotate(360deg) translate(0vmax,-10vmax);
	}
  50% {
    -webkit-transform: rotate(180deg) translate(0vmax,0vmax);
            transform: rotate(180deg) translate(0vmax,0vmax);
  }
	100% {
    -webkit-transform: rotate(0deg) translate(0vmax,-10vmax);
            transform: rotate(0deg) translate(0vmax,-10vmax);
	}
}
.bulbaSpinnerDialogBoxPanel {
	padding: 0px 10px 10px 10px;
	border-radius: 8px;
}

.bulbaSpinner {
	position: absolute;
	top: calc(50% - 150px);
	left: calc(50% - 150px);
	width: 300px;
	height: 300px;
	border-radius: 50%;
	-webkit-perspective: 800px;
	        perspective: 800px;
}

.bulbaSpinnerInner {
	position: absolute;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	border-radius: 50%;
}

.bulbaSpinnerInner.one {
	left: 0%;
	top: 0%;
	-webkit-animation: rotate-one 1s linear infinite;
	        animation: rotate-one 1s linear infinite;
	border-bottom: 5px solid white;
}

.bulbaSpinnerInner.two {
	right: 0%;
	top: 0%;
	-webkit-animation: rotate-two 1s linear infinite;
	        animation: rotate-two 1s linear infinite;
	border-right: 5px solid crimson;
}

.bulbaSpinnerInner.three {
	right: 0%;
	bottom: 0%;
	-webkit-animation: rotate-three 1s linear infinite;
	        animation: rotate-three 1s linear infinite;
	border-top: 5px solid cadetblue;
}

#bulbaSpinner2Group {
	position: relative;
	width: 100px;
	height: 15px;
	margin: auto;
}

.bulbaSpinner2Group {
	position: absolute;
	top: 0;
	background-color: rgb(0, 191, 255);
	width: 10px;
	height: 10px;
	animation-name: bounce_bulbaSpinner2Group;
	-o-animation-name: bounce_bulbaSpinner2Group;
	-ms-animation-name: bounce_bulbaSpinner2Group;
	-webkit-animation-name: bounce_bulbaSpinner2Group;
	-moz-animation-name: bounce_bulbaSpinner2Group;
	animation-duration: 1.69s;
	-o-animation-duration: 1.69s;
	-ms-animation-duration: 1.69s;
	-webkit-animation-duration: 1.69s;
	-moz-animation-duration: 1.69s;
	animation-iteration-count: infinite;
	-o-animation-iteration-count: infinite;
	-ms-animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
	-moz-animation-iteration-count: infinite;
	animation-direction: normal;
	-o-animation-direction: normal;
	-ms-animation-direction: normal;
	-webkit-animation-direction: normal;
	-moz-animation-direction: normal;
	transform: scale(.3);
	-o-transform: scale(.3);
	-ms-transform: scale(.3);
	-webkit-transform: scale(.3);
	-moz-transform: scale(.3);
	border-radius: 9px;
	-o-border-radius: 9px;
	-ms-border-radius: 9px;
	-webkit-border-radius: 9px;
	-moz-border-radius: 9px;
}

#bulbaSpinner2Group_1 {
	left: 0;
	animation-delay: 0.68s;
	-o-animation-delay: 0.68s;
	-ms-animation-delay: 0.68s;
	-webkit-animation-delay: 0.68s;
	-moz-animation-delay: 0.68s;
}

#bulbaSpinner2Group_2 {
	left: 13px;
	animation-delay: 0.85s;
	-o-animation-delay: 0.85s;
	-ms-animation-delay: 0.85s;
	-webkit-animation-delay: 0.85s;
	-moz-animation-delay: 0.85s;
}

#bulbaSpinner2Group_3 {
	left: 27px;
	animation-delay: 1.01s;
	-o-animation-delay: 1.01s;
	-ms-animation-delay: 1.01s;
	-webkit-animation-delay: 1.01s;
	-moz-animation-delay: 1.01s;
}

#bulbaSpinner2Group_4 {
	left: 40px;
	animation-delay: 1.18s;
	-o-animation-delay: 1.18s;
	-ms-animation-delay: 1.18s;
	-webkit-animation-delay: 1.18s;
	-moz-animation-delay: 1.18s;
}

#bulbaSpinner2Group_5 {
	left: 54px;
	animation-delay: 1.35s;
	-o-animation-delay: 1.35s;
	-ms-animation-delay: 1.35s;
	-webkit-animation-delay: 1.35s;
	-moz-animation-delay: 1.35s;
}

#bulbaSpinner2Group_6 {
	left: 67px;
	animation-delay: 1.52s;
	-o-animation-delay: 1.52s;
	-ms-animation-delay: 1.52s;
	-webkit-animation-delay: 1.52s;
	-moz-animation-delay: 1.52s;
}

#bulbaSpinner2Group_7 {
	left: 81px;
	animation-delay: 1.69s;
	-o-animation-delay: 1.69s;
	-ms-animation-delay: 1.69s;
	-webkit-animation-delay: 1.69s;
	-moz-animation-delay: 1.69s;
}

#bulbaSpinner2Group_8 {
	left: 94px;
	animation-delay: 1.86s;
	-o-animation-delay: 1.86s;
	-ms-animation-delay: 1.86s;
	-webkit-animation-delay: 1.86s;
	-moz-animation-delay: 1.86s;
}

@keyframes bounce_bulbaSpinner2Group {
	0%		{-webkit-transform: scale(1);transform: scale(1); background-color: rgb(0, 191, 255);}
	100%	{-webkit-transform:scale(.3);transform:scale(.3); background-color :rgb(255,255,255);}
}

@-webkit-keyframes bounce_bulbaSpinner2Group {
	0%		{-webkit-transform: scale(1); background-color: rgb(0, 191, 255);}
	100%	{-webkit-transform: scale(.3); background-color: rgb(255,255,255);}
}

@-webkit-keyframes rotate-one {
	0% 		{ -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg); transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);}
	100% 	{ -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg); transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);}
}

@keyframes rotate-one {
	0% 		{ -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg); transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);}
	100% 	{ -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg); transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);}
}

@-webkit-keyframes rotate-two {
	0% 		{-webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);}
	100%	{-webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);}
}

@keyframes rotate-two {
	0% 		{-webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);}
	100%	{-webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);}
}

@-webkit-keyframes rotate-three {
	0% 		{-webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);}
	100%	{-webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);}
}

@keyframes rotate-three {
	0% 		{-webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);}
	100%	{-webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);}
}

.dashboard-client-category .css-1jllj6i-control{
    display: none;
}

.dashboard-client-category{ 
    box-shadow: 0px 2px 14px -5px rgba(0,0,0,0.94);
    /* border:1px solid rgb(144,197,68) !important; */


} 



.css-1r4vtzz{
    /* -webkit-box-shadow: 0px 2px 12px -3px rgba(0,0,0,0.57); 
    box-shadow: 0px 2px 12px -3px rgba(0,0,0,0.57) !important; */
    border:1px solid rgba(163, 147, 147,.1) !important;
   
}
.css-1r4vtzz:hover{
    /* -webkit-box-shadow: 0px 2px 12px -3px rgba(0,0,0,0.57); 
    box-shadow: 0px 2px 12px -3px rgba(0,0,0,0.57) !important; */
    border:1px solid rgba(163, 147, 147,.3) !important;
   
}
/* 20200403 build 1 - Africk */
/* #Tablet (Landscape) Design for a width of 1024px */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .insurance-dashboard-container{
        /* background-color: yellow; */
        width:84% !important;
        position: absolute;
        height: auto !important;
        position: relative;  /* 20200722 MABT-323 */
    }

}

/* #Tablet (Landscape) Design for a width of 1024px such as Ipad 9.7 inches */
@media only screen and (min-width: 1024px) {
    .insurance-dashboard-container{
        background-color: #ffffff;
        width: 320px !important;
        position: fixed;
        top: 57px;
        right: 100px !important;
        /* right: 100px; */
        height: 100% !important;
    }
}

/* #Mobile (Portrait) Design for a width of 320px */
@media only screen and (max-width: 767px) {
    .insurance-dashboard-container{
        /* background-color: yellow; */
        width:84% !important;
        position: relative;
        height: 100% !important;
    }
    
}

.insurance-dashboard-container{
    width: 300px;
    height: 93vh;
    /* float: right; */     /* 20200819 MABT-323 */
    border-left: 1px solid #eeeeee;
    padding: 15px 9px 15px 10px;
    overflow-y: auto;
}

.insurance-dashboard-container-shrinked{
    height: 93vh;
    float: right;
    border-left: 1px solid #eeeeee;
    padding: 15px 9px 15px 10px;
    overflow-y: auto;
    width: 60px !important;
}
