input[type="file"].upload {
    cursor: pointer !important;
    width: 100%;
    border-bottom: 1px solid #d6d6d6;
}

input[type="file"].upload::-webkit-file-upload-button {
    background: rgba(0, 174, 243, 1);
    border: 0;
    padding: 0.5em 1.5em;
    cursor: pointer;
    color: #fff;
    border-radius: .2em;
}

input[type="file"].upload::-ms-browse.upload {
    background: rgba(0, 174, 243, 1);
    border: 0;
    padding: 0.5em 1.5em;
    cursor: pointer;
    color: #fff;
    border-radius: .2em;
}